import SearchBoxContainer from '@react/search/components/SeachBoxContainer';
import NewModal, {
  HeaderWithClose,
  ModalBody,
} from '@sly/frontend/react/shared/components/NewModal';
import { useRouter } from 'next/router';
import { eventNames, useEvents } from 'sly/services/events';

import {
  OnLocationSearchEvent,
  TripleQuestionModalProps,
} from './TripleQuestionModal.types';

function TripleQuestionModal({
  showModal,
  setShowModal,
}: TripleQuestionModalProps) {
  const router = useRouter();
  const { events } = useEvents();

  const onLocationSearch = (event: OnLocationSearchEvent) => {
    // Would check if action is redirect here, but
    // as it is only redirect, no need
    router.push(event.url);
  };

  const onClose = () => {
    events.track(eventNames.ModalExited, {
      cta: 'freedomToExploreSearch',
    });
    setShowModal(false);
  };

  return (
    <NewModal
      isOpen={showModal}
      className='overflow-y-visible'
      onClose={onClose}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-ignore*/}
      <HeaderWithClose onClose={onClose} noBorder>
        <span data-tp-id='gen-span-78d86a11-84e5-4ec4-adf8-d52bb1818bb7'>
          Please enter a location:
        </span>
      </HeaderWithClose>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-ignore*/}
      <ModalBody className='overflow-y-visible'>
        <div
          className='pl-8 pr-8 pb-8 pt-2'
          data-tp-id='gen-div-4bcc3ab0-d21b-4d2c-a817-9cb1f3e3f3b0'
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <SearchBoxContainer
            eventProps={{
              location: 'explore communities',
            }}
            onLocationSearch={(e: OnLocationSearchEvent) => {
              setShowModal(false);
              onLocationSearch(e);
            }}
            className='w-full'
          />
        </div>
      </ModalBody>
    </NewModal>
  );
}

export default TripleQuestionModal;
