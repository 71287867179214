import Body from 'sly/homePage/components/Guides/Body';

import { GuidesProps } from './Guides.types';
import LeftPane from './LeftPane';
import RightPane from './RightPane';

function Guides({ guides }: GuidesProps) {
  return (
    <div
      className='mb-xxl bg-harvest-lighter-90 md:mb-[64px] lg:mb-xxxl'
      data-tp-id='gen-div-fa722cf7-0900-4ad1-b3f2-35be7b5a9487'
    >
      <Body>
        <LeftPane
          heading={guides.heading}
          title={guides.title}
          buttonLink={guides.buttonLink}
          buttonText={guides.buttonText}
        />
        <RightPane guidesList={guides.guidesList} />
      </Body>
    </div>
  );
}

export default Guides;
