import Section from '@react/shared/components/Section';

import { BodyProps } from './Body.types';

function Body({ children }: BodyProps) {
  return (
    <Section className='flex flex-col py-xxl !px-l md:py-[64px] md:px-l lg:flex-row lg:py-xxxl'>
      {children}
    </Section>
  );
}

export default Body;
