import { default as Image } from '@react/shared/components/Image';
import { leadSourceMap } from '@sly/core/constants/uuidActions';
import Cta from '@sly/frontend/react/cta/Cta';
import { getEventProps } from '@sly/frontend/react/cta/helpers/getEventProps';
import { useRouter } from 'next/router';
import ImageGrid from 'sly/homePage/components/ImageGrid';
import clsxm from 'sly/lib/clsxm';
import Heading from 'sly/system/Heading';

import { HomePageHeaderProps } from './HomePageHeader.types';

function HomePageHeader({
  path,
  heading,
  title,
  cta,
  headingImageList,
}: HomePageHeaderProps) {
  const { query } = useRouter();
  const { eventProps } = getEventProps(cta, {}) || {};
  return (
    <header
      className={clsxm([
        'flex',
        'flex-wrap',
        'justify-start',
        'gap-xs',
        'relative',
        'mb-[80px]',
        'pt-20',
        'pb-20',
        'px-0',
        'md:py-[64px]',
        'lg:py-xxxl',
      ])}
    >
      <Image
        path={path}
        alt='A Home To Love'
        className='absolute top-0 left-0 block h-full w-full object-cover opacity-80'
        loading='eager'
        priority
        data-tp-id='gen-Image-dce2eaa0-0f80-46d9-80d3-ce5bd54ce2d1'
      />
      <section className='z-100 relative mx-auto flex flex-col px-4 md:w-[var(--col8)] lg:w-[var(--col12)] lg:flex-row lg:items-center'>
        <div
          className='mb-xxl md:mb-[64px] lg:mb-0 lg:mr-[64px]'
          data-tp-id='gen-div-dcb653d5-a63f-41d1-bb5c-3f0593375adf'
        >
          <Heading
            as='h1'
            font='font-t-xxl'
            className='mb-l'
            data-tp-id='gen-Heading-fe7025c0-70c8-4060-b14a-a8ee34dbb6c0'
          >
            {heading}
          </Heading>
          <div
            style={{ font: '400 18px/32px Azo Sans,Roboto,sans-serif' }}
            className='mb-6'
            data-tp-id='gen-div-b637d5b4-ee51-4ee9-b7b2-7044f953d28f'
          >
            {title}
          </div>
          {cta && (
            <Cta
              showLocationStep={true}
              params={query}
              ctaProps={cta}
              mode={{
                cta: 'getStarted',
                entry: 'homepage',
                leadSource: leadSourceMap.HomepageGetStartedLS,
              }}
              className='inline-block h-l w-full !py-m md:w-auto md:px-xxl'
              startLink='/wizards/assessment'
              eventProps={{
                ...eventProps,
                entry: 'headerSection',
                location: cta?.type,
              }}
              isOpenByDefault={query?.section === 'wizard'}
            />
          )}
        </div>
        <ImageGrid headingImageList={headingImageList} />
      </section>
    </header>
  );
}

export default HomePageHeader;
