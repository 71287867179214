import useScrollObserver from '@react/shared/hooks/useScrollObserver';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SearchCity from 'sly/homePage/components/CommunitiesByCity/SearchCity';
import Chevron from 'sly/icons/Chevron';
import Heading from 'sly/system/Heading';

import Body from './Body';
import CityTile from './CityTile';
import { CommunitiesByCityProps } from './CommunitiesByCity.types';
import GridButton from './GridButton';

function CommunitiesByCity({ communitiesByCity }: CommunitiesByCityProps) {
  const [position, setPosition] = useState(0);
  const { ref, combined: dimensions } = useScrollObserver();

  const [max, step] =
    useMemo(() => {
      return [
        (dimensions.scrollX + 48) / (240 + 16),
        Math.floor(dimensions.clientWidth / (240 + 16)),
      ];
    }, [dimensions]) || 0;

  const move = useCallback(
    (direction: -1 | 1) => {
      const newPos = Math.min(Math.max(0, position + direction * step), max);
      setPosition(newPos);
    },
    [max, step, position]
  );

  useEffect(() => {
    if (!ref?.current) return;
    ref.current.scroll({
      left: position * (240 + 16),
      behavior: 'smooth',
    });
  }, [position, ref]);
  return (
    <>
      <Body>
        <Heading
          font='font-t-xl'
          className='p-m'
          data-tp-id='gen-Heading-6bf7e4f8-b460-46bd-a7c5-0f145ce45542'
        >
          {communitiesByCity.heading}
        </Heading>
        <div
          className='font-b-l'
          data-tp-id='gen-div-67263355-c1c7-4cad-b51b-322bcd7d250b'
        >
          {communitiesByCity.title}
        </div>
      </Body>
      {communitiesByCity.imageList && (
        <div
          className='relative'
          data-tp-id='gen-div-d85f2ec2-dcaf-4b90-a145-c9b1cb53ff64'
        >
          <div
            ref={ref}
            className='grid grid-cols-[repeat(15,240px)] gap-m gap-6 overflow-auto p-l lg:overflow-hidden'
            data-tp-id='gen-div-0bb7ceda-b547-4279-8ada-6599e55935da'
          >
            {communitiesByCity.imageList.map((mostSearchedCity) => {
              const {
                assistedLivingLinck: assistedLivingLink,
                subtitle,
                id,
              } = mostSearchedCity;
              const href = assistedLivingLink
                ? `/assisted-living/${assistedLivingLink}`
                : '';
              return (
                <CityTile key={id} to={href} {...mostSearchedCity}>
                  <Heading
                    font='font-t-s-azo'
                    className='p-0 text-white'
                    data-tp-id='gen-Heading-8a94bfa5-1581-4acc-8f5c-71a6d64d9c0f'
                  >
                    {subtitle}
                  </Heading>
                  <div
                    className='font-b-s text-white'
                    data-tp-id='gen-div-77a17250-f539-4f5f-a537-5d5bd150226f'
                  >
                    Explore now <Chevron className='rotate-90' size='s' />
                  </div>
                </CityTile>
              );
            })}
            <GridButton
              direction='left'
              onClick={() => move(-1)}
              name='cbc-left-arrow'
            />
            <GridButton
              direction='right'
              onClick={() => move(+1)}
              name='cbc-right-arrow'
            />
          </div>
        </div>
      )}
      <SearchCity secondHeading={communitiesByCity.secondHeading} />
    </>
  );
}

export default CommunitiesByCity;
