import { Link } from 'sly/system';
import Heading from 'sly/system/Heading';

function City({ cityString, tab }: { cityString: string; tab: string }) {
  const tabToLinkDict = {
    'Assisted Living': '/assisted-living',
    'Independent Living': '/independent-living',
    'Memory Care': '/memory-care',
  };
  const cityStringArray = cityString.split(',');
  const link = cityStringArray[0];
  const cityName = cityStringArray
    .slice(1, cityStringArray.length - 1)
    .join(',');
  const communityCount = cityStringArray[cityStringArray.length - 1];
  const community = `${communityCount} ${
    communityCount === '1' ? 'community' : 'communities'
  }`;
  const processedLink = `${
    tabToLinkDict[tab as keyof typeof tabToLinkDict]
  }/${link}`;
  return (
    <div className='mb-6'>
      <Link to={processedLink}>
        <Heading font='font-t-m' className='mb-1'>
          {cityName.split('-').join(' ')}
        </Heading>
      </Link>
      <span className='font-b-s'>{community}</span>
    </div>
  );
}

export default function ExploreByCitySection({
  popularCitiesForSection,
  active,
  tab,
}: {
  popularCitiesForSection: string;
  active: boolean;
  tab: string;
}) {
  return (
    <div
      className={`${
        active ? '' : 'hidden'
      } mx-auto mt-6 mb-20 w-full columns-2 md:w-[720px] md:columns-3 lg:w-[1032px] lg:columns-4`}
    >
      {popularCitiesForSection.split('\n').map((city) => (
        <City cityString={city} key={city} tab={tab} />
      ))}
    </div>
  );
}
