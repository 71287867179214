/** @jsxImportSource react */
import cx from 'classnames';
export interface SectionProps {
  children: React.ReactNode;
  className?: string;
}

function Section({ children, className }: SectionProps) {
  return (
    <div
      className={cx(
        'my-0 mx-auto w-full px-m md:w-[720px] md:px-0 lg:w-[1032px]',
        className || ''
      )}
      data-tp-id='gen-div-254aa9af-6a7b-48e5-a55f-f0f8cfc207c1'
    >
      {children}
    </div>
  );
}

export default Section;
