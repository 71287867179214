import { default as Image } from '@react/shared/components/Image';
import Link from 'sly/system/Link';

import { CityTileProps } from './CityTile.types';

function CityTile({ to, image, children, subtitle }: CityTileProps) {
  return (
    <Link
      href={to}
      eventProps={{ cta: subtitle, location: 'home explore cities' }}
      data-tp-id='gen-Link-2cb5f0e4-fd0c-4e59-8d04-21066f98f1c3'
    >
      <div
        className='relative block h-0 cursor-pointer pt-[66%]'
        data-tp-id='gen-div-7619f47d-4340-4563-a905-77c92213fc87'
      >
        <Image
          layout='raw'
          path={image.path}
          alt={image.alternativeText}
          width={200}
          height={200}
          sizes='(min-width: 768px) 150, (min-width: 1080px) 180, calc((100vw - 3rem) / 2)'
          className='absolute top-0 left-0 h-full w-full rounded-lg object-cover'
          data-tp-id='gen-Image-faa9d554-ecde-4559-88b6-26bf7794e4c9'
        />
        <div
          className='absolute top-0 h-full w-full rounded-lg p-[12px] pt-[96px] hover:bg-[#00000033]'
          data-tp-id='gen-div-35c1ef22-2b1b-4165-b67b-c442f2331a76'
        >
          {children}
        </div>
      </div>
    </Link>
  );
}

export default CityTile;
