export interface SeparatorProps {
  fullWidth?: boolean;
  className?: string;
}

export default function Separator({ fullWidth, className }: SeparatorProps) {
  const fullWidthClass = fullWidth ? 'w-[98.5vw] ml-[calc(-50vw_+_50%)]' : '';
  return (
    <hr
      className={`my-[4rem] border-[#E7E8E1] ${fullWidthClass} ${className}`}
    />
  );
}
