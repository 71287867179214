import { default as Image } from '@react/shared/components/Image';
import { ReasonsTypes } from 'sly/homePage/components/Reasons/Reasons.types';
import {
  ChatFill,
  Checkmark,
  HomeSmallStroke,
  List,
  Money,
  Offer,
  Search,
  Star,
} from 'sly/icons/index';
import Heading from 'sly/system/Heading';

const svgMap: { [key: string]: typeof Search } = {
  Search: Search,
  Checkmark: Checkmark,
  Security: Money,
  Star: Star,
  Community: HomeSmallStroke,
  List: List,
  Messages: ChatFill,
  Offer: Offer,
};

function Reasons({ sectionReasons }: ReasonsTypes) {
  if (!sectionReasons) return null;
  const imageShadow = [
    'shadow-[1.5rem_1.5rem_0_0_#e8f1f1]',
    'shadow-[-1.5rem_1.5rem_0_0_#e8f1f1]',
  ];
  const padding = ['p-[0_1.5em_0_0]', 'p-[0_0_0_1.5em]'];
  return (
    <div
      className='my-xxl mx-m flex-col justify-center align-baseline md:my-xxxl md:mx-l lg:my-xxxl lg:mx-auto lg:w-[64.5rem] lg:items-start'
      data-tp-id='gen-div-3afc1a27-5762-4585-8304-6005ceabf75e'
    >
      {sectionReasons.map((reason, index) => (
        <div
          key={index}
          className={`flex w-full flex-col md:justify-evenly ${
            index % 2 ? 'md:flex-row-reverse' : 'md:flex-row'
          } ${index < 2 && 'mb-20'}`}
          data-tp-id='gen-div-c88a0daa-69de-4164-8ffe-b0b7aa34a389'
        >
          <div
            className={`ml-0 mb-8 w-full md:h-[var(--col4)] md:w-[var(--col4)] lg:h-[28.5rem] lg:w-[28.5rem] ${
              padding[index % 2]
            }`}
            data-tp-id='gen-div-7549d01d-db7d-4942-a800-640258cadb22'
          >
            <Image
              path={reason.image.path}
              alt={reason.heading}
              layout='raw'
              width={300}
              height={300}
              className={`h-full w-full rounded-[8px] object-cover ${
                imageShadow[index % 2]
              }`}
              data-tp-id='gen-Image-cba40714-4947-42fa-8f17-3c57e696bab1'
            />
          </div>
          <div
            className='flex flex-col justify-center md:w-[var(--col4)] lg:w-[28.5rem]'
            data-tp-id='gen-div-be1d5522-264d-48af-805e-b48a60ec3e18'
          >
            <Heading
              className='font-t-l mb-m max-w-[calc(var(--col4)+1.5rem)]'
              data-tp-id='gen-Heading-9d1972f4-5849-49b9-8a25-300472414b03'
            >
              {reason.heading}
            </Heading>
            <div
              className='font-b-l description-section-homepage mb-6'
              dangerouslySetInnerHTML={{ __html: reason.description }}
              data-tp-id='gen-div-5863bcd9-26e7-4d6c-ac82-7e182f154e89'
            />
            {reason.ItemList.length > 0 && (
              <div
                className='grid flex-row gap-s'
                data-tp-id='gen-div-edc10191-bbe0-4661-a06b-b8e92cd9bd27'
              >
                {reason.ItemList.map((elem, itemListIndex) => {
                  const Icon = svgMap[elem.icon];
                  return (
                    <div
                      key={itemListIndex}
                      className='flex flex-row align-baseline'
                      data-tp-id='gen-div-6565781a-0bb8-4795-a5cd-94e526c1a349'
                    >
                      {Icon && (
                        <Icon className='mr-s mt-auto mb-auto text-harvest-base' />
                      )}
                      {elem.text}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Reasons;
