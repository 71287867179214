import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

import useDimensions from './useDimensions';

function useScrollObserver() {
  const [scroll, setScroll] = useState({
    scrollWidth: 0,
    scrollHeight: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const { ref, dimensions } = useDimensions();

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    function onScroll() {
      if (!ref?.current) {
        return;
      }
      setScroll({
        scrollWidth: ref.current.scrollWidth,
        scrollHeight: ref.current.scrollHeight,
        scrollLeft: ref.current.scrollLeft,
        scrollTop: ref.current.scrollTop,
      });
    }

    onScroll();

    const debounced = debounce(onScroll);

    ref.current?.addEventListener('scroll', debounced);
    // eslint-disable-next-line consistent-return
    return function cleanup() {
      ref.current?.removeEventListener('scroll', debounced);
    };
  }, [ref, dimensions]);

  const combined = useMemo(
    () => ({
      ...scroll,
      ...dimensions,
      scrollX: scroll?.scrollWidth - dimensions?.clientWidth,
      scrollY: scroll?.scrollHeight - dimensions?.clientHeight,
    }),
    [scroll, dimensions]
  );

  return { ref, combined };
}

export default useScrollObserver;
