import { default as Image } from '@react/shared/components/Image';
import { HeadingImageList } from 'sly/homePage/components/HomePage/HomePage.types';

function ImageGrid({
  headingImageList,
}: {
  headingImageList: HeadingImageList[];
}) {
  if (!headingImageList) return null;
  return (
    <div
      className='grid grid-cols-[1fr_1fr] flex-row gap-4 lg:w-[376px] lg:flex-shrink-0 lg:grid-cols-none'
      data-tp-id='gen-div-4c4c62b2-a655-4b43-80c2-28b05feb2322'
    >
      <div
        className='grid gap-4 md:grid-cols-[1fr_1fr]'
        data-tp-id='gen-div-a0140f91-225c-43d1-a47c-d906a8355bc9'
      >
        {headingImageList
          .filter((_el, index: number) => index <= 1)
          .map((img, index) => (
            <div
              key={index}
              className='relative block h-0 w-full pt-[100%]'
              data-tp-id='gen-div-d5cdba8d-af50-4991-b169-ae9fe23d29d7'
            >
              <Image
                path={img.image.path}
                alt={`face${index}`}
                loading='eager'
                layout='raw'
                width={180}
                height={180}
                sizes='(min-width: 768px) 150, (min-width: 1080px) 180, calc((100vw - 3rem) / 2)'
                className='absolute top-0 left-0 h-full w-full object-cover'
                data-tp-id='gen-Image-6854bf2a-fbc4-4aad-8c77-c71d0023fdee'
              />
            </div>
          ))}
      </div>
      <div
        className='grid gap-4 md:grid-cols-[1fr_1fr]'
        data-tp-id='gen-div-0bf14097-6cdd-427e-bbbb-161bdf11d23b'
      >
        {headingImageList
          .filter((_el, index) => index > 1)
          .map((img, index) => (
            <div
              key={index}
              className='relative block h-0 w-full pt-[100%]'
              data-tp-id='gen-div-d78c14c3-fe3c-47b3-b95e-e1af8803b113'
            >
              <Image
                path={img.image.path}
                alt={`face${index}`}
                loading='eager'
                key={index}
                layout='raw'
                width={180}
                height={180}
                sizes='(min-width: 768px) 150, (min-width: 1080px) 180, calc((100vw - 3rem) / 2)'
                className='absolute top-0 left-0 h-full w-full object-cover'
                data-tp-id='gen-Image-c69ec890-bb24-4fb3-ae07-6f029745a105'
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ImageGrid;
