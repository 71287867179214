import SearchBoxContainer from '@react/search/components/SeachBoxContainer';
import { useRouter } from 'next/router';
import { OnLocationSearchEvent } from 'sly/homePage/components/TripleQuestion/TripleQuestionModal/TripleQuestionModal.types';
import Heading from 'sly/system/Heading';

import { SearchCityProps } from './SearchCity.types';

function SearchCity({ secondHeading }: SearchCityProps) {
  const router = useRouter();

  const onLocationSearch = (event: OnLocationSearchEvent) => {
    // Would check if action is redirect here, but
    // as it is only redirect, no need
    router.push(event.url);
  };

  return (
    <div
      className='flex flex-col items-center p-m pb-xxl md:pb-xxxl'
      data-tp-id='gen-div-a1d346df-9563-4e32-ad8f-3852fa24f328'
    >
      <Heading
        font='font-t-m'
        className='p-m'
        data-tp-id='gen-Heading-64e93210-1ac5-49d0-bee4-63ea045ee8a4'
      >
        {secondHeading}
      </Heading>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-ignore*/}
      <SearchBoxContainer
        className='w-full md:max-w-[360px]'
        eventProps={{
          location: 'home explore cities',
        }}
        onLocationSearch={onLocationSearch}
      />
    </div>
  );
}

export default SearchCity;
