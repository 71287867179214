import Chevron from 'sly/icons/Chevron';

import {
  GridButtonCarouselButtonProps,
  GridButtonProps,
  GridButtonWrapperProps,
} from './GridButton.types';

function Wrapper({
  children,
  className,
  onClick,
  name,
}: GridButtonWrapperProps) {
  return (
    <div
      className={`flex h-[48px] w-[48px] cursor-pointer rounded-[24px] bg-white drop-shadow-[0_2px_8px_rgba(0,0,0,0.1)] ${className}`}
      onClick={onClick}
      data-tp={name}
      data-tp-id='gen-div-b59bf3cd-ec3c-4d0e-9252-910ff526764c'
    >
      {children}
    </div>
  );
}

export function CarouselButton({
  colour,
  size,
  className,
  onClick,
  name,
}: GridButtonCarouselButtonProps) {
  return (
    <Wrapper className={className} onClick={onClick} name={name}>
      <Chevron color={colour} size={size} className='m-auto align-middle' />
    </Wrapper>
  );
}

function GridButton({ direction, onClick, name }: GridButtonProps) {
  return (
    <CarouselButton
      className={`absolute top-[160px] ${
        direction === 'left'
          ? 'left-[24px] -rotate-90'
          : 'right-[24px] rotate-90'
      }`}
      onClick={onClick}
      size='m'
      name={name}
    />
  );
}

export default GridButton;
