import { eventNames, useEvents } from '@sly/frontend/react/services/events';
import { CarouselButton } from 'sly/homePage/components/CommunitiesByCity/GridButton';

import { QuoteControlsProps } from './QuoteControls.types';

function QuoteControls({ move }: QuoteControlsProps) {
  const { events } = useEvents();
  return (
    <div
      className='flex justify-center'
      data-tp-id='gen-div-406e2412-8b2e-40bd-8f5a-e6a81956e251'
    >
      <CarouselButton
        className='mr-xxl -rotate-90'
        onClick={() => {
          events.track(eventNames.ButtonClicked, {
            location: 'homepage-testimonial-caroussel',
            action: 'click',
            text: 'left-arrow',
            label: 'left-arrow',
          });
          move(false);
        }}
        name='qc-left-arrow'
      />
      <CarouselButton
        className='rotate-90'
        onClick={() => {
          events.track(eventNames.ButtonClicked, {
            location: 'homepage-testimonial-caroussel',
            action: 'click',
            text: 'right-arrow',
            label: 'right-arrow',
          });
          move(true);
        }}
        name='qc-right-arrow'
      />
    </div>
  );
}

export default QuoteControls;
