import { default as Image } from '@react/shared/components/Image';
import GettingStartedHalf from 'sly/homePage/components/GetStarted/GettingStartedHalf';

import { GetStartedProps } from './GetStarted.types';

function GetStarted({ description, pathImg, cta }: GetStartedProps) {
  return (
    <div
      className='flex grid-cols-2 flex-col-reverse md:grid md:flex-row'
      data-tp-id='gen-div-c00098f0-9f1d-4857-8db9-6c2009f49000'
    >
      <GettingStartedHalf description={description} cta={cta} />
      <div
        className='relative block h-0 w-full pt-[240px] md:pt-[388px] lg:pt-[396px]'
        data-tp-id='gen-div-21430e65-37d8-4442-8bb8-43edb49c157a'
      >
        <Image
          path={pathImg}
          alt='bottom-banner'
          className='absolute top-0 left-0 h-full w-full object-cover'
          layout='raw'
          width={300}
          height={300}
          sizes='(min-width: 768px) 150, (min-width: 1080px) 180, calc((100vw - 3rem) / 2)'
          data-tp-id='gen-Image-a8ec752a-f785-413a-b6cd-19d644f61439'
        />
      </div>
    </div>
  );
}

export default GetStarted;
