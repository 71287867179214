import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M5.00001 21.275C4.37029 21.275 3.8337 21.0533 3.39023 20.6098C2.94675 20.1663 2.72501 19.6297 2.72501 19V9.00001C2.72501 8.37029 2.94675 7.8337 3.39023 7.39023C3.8337 6.94675 4.37029 6.72501 5.00001 6.72501H6.72501V5.00001C6.72501 4.37029 6.94675 3.8337 7.39023 3.39023C7.8337 2.94675 8.37029 2.72501 9.00001 2.72501H15C15.6297 2.72501 16.1663 2.94675 16.6098 3.39023C17.0533 3.8337 17.275 4.37029 17.275 5.00001V10.725H19C19.6297 10.725 20.1663 10.9467 20.6098 11.3902C21.0533 11.8337 21.275 12.3703 21.275 13V19C21.275 19.6297 21.0533 20.1663 20.6098 20.6098C20.1663 21.0533 19.6297 21.275 19 21.275H13V17H11V21.275H5.00001ZM5.00001 19H7.00001V17H5.00001V19ZM5.00001 15H7.00001V13H5.00001V15ZM5.00001 11H7.00001V9.00001H5.00001V11ZM9.00001 15H11V13H9.00001V15ZM9.00001 11H11V9.00001H9.00001V11ZM9.00001 7.00001H11V5.00001H9.00001V7.00001ZM13 15H15V13H13V15ZM13 11H15V9.00001H13V11ZM13 7.00001H15V5.00001H13V7.00001ZM17 19H19V17H17V19ZM17 15H19V13H17V15Z"/>
</svg>`;

const HomeLarge = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='homelarge' svg={svg} {...props} />
  )
);

HomeLarge.displayName = 'HomeLargeIcon';

export default HomeLarge;
