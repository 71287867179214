import { Result } from 'sly/homePage/components/HomePage/HomePage.types';

const defaults: RawHomepageData = {
  hasStarted: true,
  isLoading: false,
  body: {
    data: {
      id: 1,
      chatbotAutoload: true,
      chatbotAutoloadName: 'home-page-test',
      chatbotAutoloadTime: 3000,
      createdAt: '2021-12-06T03:33:14.721Z',
      updatedAt: '2022-04-29T05:39:42.527Z',
      publishedAt: '2021-12-06T03:34:30.299Z',
      mainBlock: {
        id: 1,
        heading: 'Find a senior living community you’ll love.',
        title:
          'Seniorly makes it easier to choose the right community for your needs and budget. And it’s free.',
        buttonText: 'Get started',
        cta: {
          type: 'gwizzy',
          text: 'Get started',
          prop: 'homebase',
          variant: 'primary',
          palette: 'viridian',
          contactInfo: 'email_and_phone',
          enableOneToMany: true,
        },
        headingImageList: [
          {
            id: 1,
            image: {
              id: 75,
              name: 'hero-1.png',
              alternativeText: '',
              caption: '',
              width: 180,
              height: 180,
              formats: null,
              hash: 'hero_1_07041151d8',
              ext: '.png',
              mime: 'image/png',
              size: 2.91,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/aca0d18282c848f5386ae882403e5cb8/hero-1.png',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T05:55:42.456Z',
              updated_at: '2022-04-27T05:55:42.476Z',
              path: 'aca0d18282c848f5386ae882403e5cb8/hero-1.png',
            },
          },
          {
            id: 2,
            image: {
              id: 76,
              name: 'hero-2.png',
              alternativeText: '',
              caption: '',
              width: 180,
              height: 180,
              formats: null,
              hash: 'hero_2_da695bb452',
              ext: '.png',
              mime: 'image/png',
              size: 2.6,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/c9af2b78c7e52096f72e3127e30b510e/hero-2.png',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T05:55:42.492Z',
              updated_at: '2022-04-27T05:55:42.508Z',
              path: 'c9af2b78c7e52096f72e3127e30b510e/hero-2.png',
            },
          },
          {
            id: 3,
            image: {
              id: 77,
              name: 'hero-4.png',
              alternativeText: '',
              caption: '',
              width: 180,
              height: 180,
              formats: null,
              hash: 'hero_4_4feb6326f8',
              ext: '.png',
              mime: 'image/png',
              size: 2.97,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/b10c88bd682522e1b455d5a6957f2f68/hero-4.png',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T05:55:42.908Z',
              updated_at: '2022-04-27T05:55:42.920Z',
              path: 'b10c88bd682522e1b455d5a6957f2f68/hero-4.png',
            },
          },
          {
            id: 4,
            image: {
              id: 74,
              name: 'hero-3.png',
              alternativeText: '',
              caption: '',
              width: 180,
              height: 180,
              formats: null,
              hash: 'hero_3_cbc129fbcb',
              ext: '.png',
              mime: 'image/png',
              size: 3.36,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/7b8199656035eaf0f216fdbcd4c3ed2c/hero-3.png',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T05:55:42.454Z',
              updated_at: '2022-04-27T05:55:42.473Z',
              path: '7b8199656035eaf0f216fdbcd4c3ed2c/hero-3.png',
            },
          },
        ],
        mainImg: {
          id: 73,
          name: 'Home main.jpg',
          alternativeText: '',
          caption: '',
          width: 768,
          height: 288,
          formats: null,
          hash: 'Home_main_161d68fc40',
          ext: '.jpg',
          mime: 'image/jpeg',
          size: 1.55,
          url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/2b9856326b946e470ce97c86b5dce04e/Home%2520main.jpg',
          previewUrl: null,
          provider: 'aws-s3-custom',
          provider_metadata: null,
          created_at: '2022-04-27T05:51:22.620Z',
          updated_at: '2022-04-27T05:51:22.658Z',
          path: '2b9856326b946e470ce97c86b5dce04e/Home%20main.jpg',
        },
      },
      threeColumnWithButton: {
        id: 1,
        heading: 'Why do millions families use Seniorly every year?',
        iconList: [
          {
            id: 1,
            icon: 'advisor',
            title: 'Your Own Advisor',
            value:
              '<p>We connect you with a Seniorly Local Advisor, our trusted partner who knows the communities in your area. Rely on your advisor as much or as little as you need to find a new home you’ll love.</p>',
            cta: {
              id: 45,
              name: null,
              palette: 'yellow',
              path: null,
              prop: null,
              text: 'Speak with an expert',
              type: 'assessment_wizard',
              variant: 'primary',
            },
          },
          {
            id: 2,
            icon: 'smart_search',
            title: 'Our Smart Search ',
            value:
              '<p>Take our short quiz to set your personal preferences, then see the communities we recommend for you. Seniorly Smart Search helps you make sense of your options and choose wisely.</p>',
            cta: {
              id: 46,
              type: 'assessment_wizard',
              name: null,
              path: null,
              text: 'Take our quiz',
              prop: null,
              variant: 'primary',
              palette: 'viridian',
            },
          },
          {
            id: 3,
            icon: 'freedom_to_explore',
            title: 'Freedom to Explore',
            value:
              '<p>Want to explore communities on your own? No problem. We give you the tools to navigate through over 60,000 of the best communities—with access to pricing and exclusive photos.</p>',
            cta: {
              id: 47,
              type: 'assessment_wizard',
              name: null,
              path: null,
              text: 'Explore Communities',
              prop: null,
              variant: 'primary',
              palette: 'harvest',
            },
          },
        ],
      },
      sectionReasons: [
        {
          id: 1,
          heading: 'A Smarter Way to Find Your Next Home',
          description:
            '<p>Our <i><strong>Seniorly</strong> Smart Search</i> advanced technology and network of knowledgeable local experts work together to guide you to the next home you’ll love.</p>',
          ItemList: [],
          image: {
            id: 78,
            name: 'Home-r-1.png',
            alternativeText: '',
            caption: '',
            width: 768,
            height: 768,
            formats: null,
            hash: 'Home_r_1_24fe378f15',
            ext: '.png',
            mime: 'image/png',
            size: 18.91,
            url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/94fece10abfc00d3396e63e11dd151e5/Home-r-1.png',
            previewUrl: null,
            provider: 'aws-s3-custom',
            provider_metadata: null,
            created_at: '2022-04-27T06:03:25.332Z',
            updated_at: '2022-04-27T06:03:25.352Z',
            path: '94fece10abfc00d3396e63e11dd151e5/Home-r-1.png',
          },
        },
        {
          id: 2,
          heading: 'Your Seniorly Local Advisor',
          description:
            '<p>After you complete our Smart Search, you’ll work with a <i><strong>Seniorly</strong> Local Advisor</i>, your own expert who guides from the first step of your senior living journey—to the day you settle in to your new home.&nbsp;</p>',
          ItemList: [],
          image: {
            id: 79,
            name: 'home-r-2.png',
            alternativeText: '',
            caption: '',
            width: 768,
            height: 768,
            formats: null,
            hash: 'home_r_2_6d809ef7ce',
            ext: '.png',
            mime: 'image/png',
            size: 30.26,
            url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/e1ea5c98c75363a5186678380391caa3/home-r-2.png',
            previewUrl: null,
            provider: 'aws-s3-custom',
            provider_metadata: null,
            created_at: '2022-04-27T06:04:51.999Z',
            updated_at: '2022-04-27T06:04:52.083Z',
            path: 'e1ea5c98c75363a5186678380391caa3/home-r-2.png',
          },
        },
        {
          id: 3,
          heading: 'Your Home Base',
          description:
            '<p>We keep your search results, advisor contact info, and your communications in a private, secure&nbsp;</p><p><i><strong>Seniorly</strong> Home Base</i> to help you stay organized along the way. It’s easy to communicate with your advisor and change your preferences to explore different types of communities.&nbsp;</p>',
          ItemList: [],
          image: {
            id: 80,
            name: 'home-r-3.png',
            alternativeText: '',
            caption: '',
            width: 768,
            height: 768,
            formats: null,
            hash: 'home_r_3_50d139a32c',
            ext: '.png',
            mime: 'image/png',
            size: 19.58,
            url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/f4e545d9a77c4c0890a82da69baabd5a/home-r-3.png',
            previewUrl: null,
            provider: 'aws-s3-custom',
            provider_metadata: null,
            created_at: '2022-04-27T06:06:05.022Z',
            updated_at: '2022-04-27T06:06:05.099Z',
            path: 'f4e545d9a77c4c0890a82da69baabd5a/home-r-3.png',
          },
        },
      ],
      guides: {
        id: 1,
        heading: 'Resources and Helpful Guides',
        title:
          'When you first begin to explore senior living, the information and options can be overwhelming—and stressful. We get it. That’s why we’ve done the basic research for you, creating a series of Seniorly Guides and reliable resources to inform you along your journey.  ',
        buttonText: 'Explore our resource center',
        buttonLink: '/resource-center',
        guidesList: [
          {
            id: 1,
            title: 'Assisted Living Guide',
            link: '/assisted-living',
            alt: 'Assisted Living Guide',
          },
          {
            id: 2,
            title: 'Memory Care Guide',
            link: '/memory-care',
            alt: 'memory care senior living seniorly',
          },
          {
            id: 3,
            title: 'Independent Living Guide',
            link: '/independent-living',
            alt: 'independent living senior living seniorly',
          },
          {
            id: 4,
            title: 'Continuing Care Retirement Community Guide',
            link: '/continuing-care-retirement-community',
            alt: 'ccrc senior living seniorly',
          },
          {
            id: 5,
            title: 'Home Care Guide',
            link: '/in-home-care',
            alt: 'in home care',
          },
        ],
      },
      quotesCarousel: {
        id: 1,
        heading: 'Families love the Seniorly experience.',
        carouselsList: [
          {
            id: 1,
            author: 'Annie S.',
            text: 'This whole experience has changed our viewpoint of senior living. We thank Seniorly for the immediate, thorough, and compassionate care you provided.',
          },
          {
            id: 2,
            author: 'Sheryl O.',
            text: "How could I give anything but a 10/10? Mom is moving to a great home with wonderful women- and she's stunned she has this opportunity.",
          },
          {
            id: 3,
            author: 'Amy B.',
            text: "Sandy was really helpful, He didn't tell me what I wanted or hoped to hear, but told me what I needed to know, which was even better!",
          },
          {
            id: 4,
            author: 'Cindy H.',
            text: 'Rachelle responded promptly and got right to work sending us recommendations. After we found a spot she checked back in to see if we needed any help. Polite, respectful and very professional.',
          },
          {
            id: 5,
            author: 'Ronna S.',
            text: "Donlyn was so helpful and quickly contacted several homes for us to tour. We found a perfect fit for my mom and couldn't be happier. It's wonderful to know that I am not on this journey alone.",
          },
          {
            id: 6,
            author: 'Gretchen H.',
            text: "Lisa called me and was very encouraging. At the end of our conversation I felt more peaceful and less anxious. Thankful for all you folks who care and advise during life's inevitable difficult seasons.",
          },
          {
            id: 7,
            author: 'Pam E.',
            text: 'Kriste was amazing. I truely believe that she was an angel sent to help me. She was caring, knowledgable and honest. I am so blessed to have her as my advisor!',
          },
        ],
      },
      CommunitiesByCity: {
        id: 1,
        heading: 'Explore communities by city.',
        title:
          'See our exclusive photos, monthly pricing, and expert insights for each community. You can learn more about the places you like or speak with a Seniorly Local Advisor in that city.',
        secondHeading: 'Find communities in your area.',
        imageList: [
          {
            id: 1,
            assistedLivingLinck: 'california/los-angeles',
            subtitle: 'Los Angeles, CA',
            image: {
              id: 81,
              name: 'LosAngeles.jpg',
              alternativeText: '',
              caption: '',
              width: 634,
              height: 443,
              formats: null,
              hash: 'Los_Angeles_6f7acab216',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 65.38,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/9ea732952431a34602e4ee862830ef5e/LosAngeles.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T06:09:54.007Z',
              updated_at: '2022-04-27T06:09:54.023Z',
              path: '9ea732952431a34602e4ee862830ef5e/LosAngeles.jpg',
            },
          },
          {
            id: 2,
            assistedLivingLinck: 'california/san-francisco',
            subtitle: 'San Francisco, CA',
            image: {
              id: 82,
              name: 'SanFrancisco.jpg',
              alternativeText: '',
              caption: '',
              width: 6000,
              height: 4000,
              formats: null,
              hash: 'San_Francisco_6c7ad57aee',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 989.51,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/d51ad37864d6e1a9e001564c126778c4/SanFrancisco.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:27:45.775Z',
              updated_at: '2022-04-27T07:27:45.830Z',
              path: 'd51ad37864d6e1a9e001564c126778c4/SanFrancisco.jpg',
            },
          },
          {
            id: 3,
            assistedLivingLinck: 'new-york/new-york',
            subtitle: 'New York, NY',
            image: {
              id: 83,
              name: 'NewYork.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 427,
              formats: null,
              hash: 'New_York_484c5e9422',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 45.38,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/59e6ef2d40babad57d092fa9b6576bd8/NewYork.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:28:18.602Z',
              updated_at: '2022-04-27T07:28:18.614Z',
              path: '59e6ef2d40babad57d092fa9b6576bd8/NewYork.jpg',
            },
          },
          {
            id: 4,
            assistedLivingLinck: 'california/san-diego',
            subtitle: 'San Diego, CA',
            image: {
              id: 84,
              name: 'SanDiego.jpg',
              alternativeText: '',
              caption: '',
              width: 3992,
              height: 2992,
              formats: null,
              hash: 'San_Diego_53a8080ae2',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 494.17,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/5ff8c27840eff73e5a1bfe1d91204a58/SanDiego.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:28:54.070Z',
              updated_at: '2022-04-27T07:28:54.084Z',
              path: '5ff8c27840eff73e5a1bfe1d91204a58/SanDiego.jpg',
            },
          },
          {
            id: 5,
            assistedLivingLinck: 'arizona/phoenix',
            subtitle: 'Phoenix, AZ',
            image: {
              id: 85,
              name: 'Phoenix.jpg',
              alternativeText: '',
              caption: '',
              width: 3568,
              height: 2000,
              formats: null,
              hash: 'Phoenix_d87b6cca1f',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 811.27,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/d0fbe91eb68186e4c45f67bc2c8a0666/Phoenix.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:29:44.364Z',
              updated_at: '2022-04-27T07:29:44.378Z',
              path: 'd0fbe91eb68186e4c45f67bc2c8a0666/Phoenix.jpg',
            },
          },
          {
            id: 6,
            assistedLivingLinck: 'illinois/chicago',
            subtitle: 'Chicago, IL',
            image: {
              id: 86,
              name: 'Chicago.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 394,
              formats: null,
              hash: 'Chicago_d745a22459',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 70.86,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/6172ad4db1ff8e9056e464c91238e4b5/Chicago.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:30:18.221Z',
              updated_at: '2022-04-27T07:30:18.242Z',
              path: '6172ad4db1ff8e9056e464c91238e4b5/Chicago.jpg',
            },
          },
          {
            id: 7,
            assistedLivingLinck: 'georgia/atlanta',
            subtitle: 'Atlanta, GA',
            image: {
              id: 87,
              name: 'Atlanta.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 427,
              formats: null,
              hash: 'Atlanta_2a906a7d49',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 36.56,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/bcfcf06e2e0ae699ec5a8250ddae36f6/Atlanta.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:31:02.990Z',
              updated_at: '2022-04-27T07:31:03.035Z',
              path: 'bcfcf06e2e0ae699ec5a8250ddae36f6/Atlanta.jpg',
            },
          },
          {
            id: 8,
            assistedLivingLinck: 'california/san-jose',
            subtitle: 'San Jose, CA',
            image: {
              id: 88,
              name: 'SanJose.jpg',
              alternativeText: '',
              caption: '',
              width: 1080,
              height: 1080,
              formats: null,
              hash: 'San_Jose_8f09c7a3ce',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 289.83,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/6946dc1dee116e65149fa7b27078b904/SanJose.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:31:32.874Z',
              updated_at: '2022-04-27T07:31:32.891Z',
              path: '6946dc1dee116e65149fa7b27078b904/SanJose.jpg',
            },
          },
          {
            id: 9,
            assistedLivingLinck: 'texas/dallas',
            subtitle: 'Dallas, TX',
            image: {
              id: 89,
              name: 'Dallas.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 320,
              formats: null,
              hash: 'Dallas_def7480674',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 46.27,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/65bbb8834a35f1188f0de8dcc8ab04bf/Dallas.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:32:23.018Z',
              updated_at: '2022-04-27T07:32:23.034Z',
              path: '65bbb8834a35f1188f0de8dcc8ab04bf/Dallas.jpg',
            },
          },
          {
            id: 10,
            assistedLivingLinck: 'texas/houston',
            subtitle: 'Houston, TX',
            image: {
              id: 90,
              name: 'Houston.jpg',
              alternativeText: '',
              caption: '',
              width: 2100,
              height: 1041,
              formats: null,
              hash: 'Houston_d883efe75f',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 548.57,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/5e027abcb4c4beaaa757ece30d30f848/Houston.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:43:11.886Z',
              updated_at: '2022-04-27T07:43:11.903Z',
              path: '5e027abcb4c4beaaa757ece30d30f848/Houston.jpg',
            },
          },
          {
            id: 11,
            assistedLivingLinck: 'california/sacramento',
            subtitle: 'Sacramento, CA',
            image: {
              id: 91,
              name: 'Sacramento.jpg',
              alternativeText: '',
              caption: '',
              width: 629,
              height: 480,
              formats: null,
              hash: 'Sacramento_de6e6d14f1',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 105.21,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/9448e9b3afa94af7b61d790678b0be9c/Sacramento.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:44:19.179Z',
              updated_at: '2022-04-27T07:44:19.209Z',
              path: '9448e9b3afa94af7b61d790678b0be9c/Sacramento.jpg',
            },
          },
          {
            id: 12,
            assistedLivingLinck: 'washington/seattle',
            subtitle: 'Seattle, WA',
            image: {
              id: 92,
              name: 'Seattle.jpg',
              alternativeText: '',
              caption: '',
              width: 512,
              height: 288,
              formats: null,
              hash: 'Seattle_b3bb1324ad',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 34.96,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/69ad80b73a1c2d9a057e24e1e619e25a/Seattle.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:44:59.971Z',
              updated_at: '2022-04-27T07:44:59.988Z',
              path: '69ad80b73a1c2d9a057e24e1e619e25a/Seattle.jpg',
            },
          },
          {
            id: 13,
            assistedLivingLinck: 'pennsylvania/philadelphia',
            subtitle: 'Philadelphia, PA',
            image: {
              id: 93,
              name: 'Philidelphia.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 427,
              formats: null,
              hash: 'Philidelphia_ad056b4e2b',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 71.1,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/a745777fd9b28c00f79daa1eecad1a46/Philidelphia.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:45:28.098Z',
              updated_at: '2022-04-27T07:45:28.126Z',
              path: 'a745777fd9b28c00f79daa1eecad1a46/Philidelphia.jpg',
            },
          },
          {
            id: 14,
            assistedLivingLinck: 'north-carolina/charlotte',
            subtitle: 'Charlotte, NC',
            image: {
              id: 94,
              name: 'Charlotte.jpg',
              alternativeText: '',
              caption: '',
              width: 540,
              height: 254,
              formats: null,
              hash: 'Charlotte_e36813a639',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 26.11,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/03a2b946a143f9c49f5c529433aaccab/Charlotte.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:46:02.052Z',
              updated_at: '2022-04-27T07:46:02.074Z',
              path: '03a2b946a143f9c49f5c529433aaccab/Charlotte.jpg',
            },
          },
          {
            id: 15,
            assistedLivingLinck: 'florida/orlando',
            subtitle: 'Orlando, Fl',
            image: {
              id: 98,
              name: 'Orlando.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 396,
              formats: null,
              hash: 'Orlando_a9b384d6ab',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 46.73,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/7e7b2a9ffe38c9bdd34608f0677843c5/Orlando.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:49:55.396Z',
              updated_at: '2022-04-27T07:49:55.410Z',
              path: '7e7b2a9ffe38c9bdd34608f0677843c5/Orlando.jpg',
            },
          },
          {
            id: 16,
            assistedLivingLinck: 'california/oakland',
            subtitle: 'Oakland, CA',
            image: {
              id: 95,
              name: 'Oakland.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 425,
              formats: null,
              hash: 'Oakland_cad06df72e',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 70.8,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/2d56417b9fb4d5cefe2881f558289b31/Oakland.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:46:31.053Z',
              updated_at: '2022-04-27T07:46:31.074Z',
              path: '2d56417b9fb4d5cefe2881f558289b31/Oakland.jpg',
            },
          },
          {
            id: 17,
            assistedLivingLinck: 'colorado/denver',
            subtitle: 'Denver, CO',
            image: {
              id: 96,
              name: 'Denver.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 393,
              formats: null,
              hash: 'Denver_2872002eaf',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 38.81,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/5542d3944468afa72a95745efa02f26e/Denver.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:47:01.754Z',
              updated_at: '2022-04-27T07:47:01.815Z',
              path: '5542d3944468afa72a95745efa02f26e/Denver.jpg',
            },
          },
          {
            id: 18,
            assistedLivingLinck: 'wisconsin/milwaukee',
            subtitle: 'Milwaukee, WI',
            image: {
              id: 97,
              name: 'Milwaukee.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Milwaukee_a0fe35d5cc',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 30.56,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/e5f31c53dd5e2982f4bea955af62fdb1/Milwaukee.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:47:43.068Z',
              updated_at: '2022-04-27T07:47:43.088Z',
              path: 'e5f31c53dd5e2982f4bea955af62fdb1/Milwaukee.jpg',
            },
          },
          {
            id: 19,
            assistedLivingLinck: 'florida/tampa',
            subtitle: 'Tampa, FL',
            image: {
              id: 99,
              name: 'Tampa.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Tampa_15ccb76588',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 29.53,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/70d22ba3784313ea646746fd46d44b1a/Tampa.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:51:42.240Z',
              updated_at: '2022-04-27T07:51:42.263Z',
              path: '70d22ba3784313ea646746fd46d44b1a/Tampa.jpg',
            },
          },
          {
            id: 20,
            assistedLivingLinck: 'florida/miami',
            subtitle: 'Miami, FL',
            image: {
              id: 100,
              name: 'Miami.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 476,
              formats: null,
              hash: 'Miami_f84d7e7557',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 73.31,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/e462962bf372cac870164b4e9f457dbc/Miami.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:52:17.398Z',
              updated_at: '2022-04-27T07:52:17.429Z',
              path: 'e462962bf372cac870164b4e9f457dbc/Miami.jpg',
            },
          },
          {
            id: 21,
            assistedLivingLinck: 'massachusetts/boston',
            subtitle: 'Boston, MA',
            image: {
              id: 101,
              name: 'Boston.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Boston_91037bb3f8',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 42.42,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/406b5ddd2968bb7c756fb0deb775645f/Boston.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:52:39.235Z',
              updated_at: '2022-04-27T07:52:39.251Z',
              path: '406b5ddd2968bb7c756fb0deb775645f/Boston.jpg',
            },
          },
          {
            id: 22,
            assistedLivingLinck: 'oregon/portland',
            subtitle: 'Portland, OR',
            image: {
              id: 102,
              name: 'Portland.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Portland_7d6b313c77',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 36.17,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/ca3f3e813ff8ed045eccd7d3523973c5/Portland.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:53:16.346Z',
              updated_at: '2022-04-27T07:53:16.361Z',
              path: 'ca3f3e813ff8ed045eccd7d3523973c5/Portland.jpg',
            },
          },
          {
            id: 23,
            assistedLivingLinck: 'arizona/tucson',
            subtitle: 'Tucson, AZ',
            image: {
              id: 103,
              name: 'Tucson.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Tucson_3a1452abeb',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 43.63,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/f1e7ae17a9a315eb3443832ced65d327/Tucson.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:54:13.043Z',
              updated_at: '2022-04-27T07:54:13.062Z',
              path: 'f1e7ae17a9a315eb3443832ced65d327/Tucson.jpg',
            },
          },
          {
            id: 24,
            assistedLivingLinck: 'texas/austin',
            subtitle: 'Austin. TX',
            image: {
              id: 104,
              name: 'Austin.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 427,
              formats: null,
              hash: 'Austin_cdad2a3809',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 69.75,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/7ea5563f237e0eab838bada2e258ffef/Austin.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:54:44.708Z',
              updated_at: '2022-04-27T07:54:44.724Z',
              path: '7ea5563f237e0eab838bada2e258ffef/Austin.jpg',
            },
          },
          {
            id: 25,
            assistedLivingLinck: 'pennsylvania/pittsburgh',
            subtitle: 'Pittsburgh, PA',
            image: {
              id: 105,
              name: 'Pittsburgh.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Pittsburgh_a6067872a7',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 46.48,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/5bff62a1348288a316feb9c5722bf0a5/Pittsburgh.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:55:10.060Z',
              updated_at: '2022-04-27T07:55:10.094Z',
              path: '5bff62a1348288a316feb9c5722bf0a5/Pittsburgh.jpg',
            },
          },
          {
            id: 26,
            assistedLivingLinck: 'texas/san-antonio',
            subtitle: 'San Antonio, TX',
            image: {
              id: 106,
              name: 'SanAntonio.jpg',
              alternativeText: '',
              caption: '',
              width: 640,
              height: 413,
              formats: null,
              hash: 'San_Antonio_94b7ccf342',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 70.16,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/f8dd2c7ea143a3e4176348aacb91efb0/SanAntonio.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:55:45.919Z',
              updated_at: '2022-04-27T07:55:45.933Z',
              path: 'f8dd2c7ea143a3e4176348aacb91efb0/SanAntonio.jpg',
            },
          },
          {
            id: 27,
            assistedLivingLinck: 'wisconsin/madison',
            subtitle: 'Madison, WI',
            image: {
              id: 107,
              name: 'Madison.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Madison_89e5aab06e',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 21.12,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/23fcb10261b5c2f445590b343df17163/Madison.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:56:11.556Z',
              updated_at: '2022-04-27T07:56:11.569Z',
              path: '23fcb10261b5c2f445590b343df17163/Madison.jpg',
            },
          },
          {
            id: 28,
            assistedLivingLinck: 'michigan/detroit',
            subtitle: 'Detroit, MI',
            image: {
              id: 108,
              name: 'Detroit.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Detroit_d9a9138e9b',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 24.44,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/781cc58dec55b45b3604cfa92117e566/Detroit.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:57:36.506Z',
              updated_at: '2022-04-27T07:57:36.526Z',
              path: '781cc58dec55b45b3604cfa92117e566/Detroit.jpg',
            },
          },
          {
            id: 29,
            assistedLivingLinck: 'arizona/scottsdale',
            subtitle: 'Scottsdale, AZ',
            image: {
              id: 109,
              name: 'Scottsdale.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Scottsdale_e240fcad0a',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 42.64,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/c48b2d12fdb6fe9436c7413de332afa7/Scottsdale.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:58:50.962Z',
              updated_at: '2022-04-27T07:58:50.988Z',
              path: 'c48b2d12fdb6fe9436c7413de332afa7/Scottsdale.jpg',
            },
          },
          {
            id: 30,
            assistedLivingLinck: 'north-carolina/raleigh',
            subtitle: 'Raleigh, NC',
            image: {
              id: 110,
              name: 'Raleigh.jpg',
              alternativeText: '',
              caption: '',
              width: 480,
              height: 320,
              formats: null,
              hash: 'Raleigh_b25e3c6526',
              ext: '.jpg',
              mime: 'image/jpeg',
              size: 53.79,
              url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/ccb498ecefa0cfba8958570428598bd1/Raleigh.jpg',
              previewUrl: null,
              provider: 'aws-s3-custom',
              provider_metadata: null,
              created_at: '2022-04-27T07:59:29.742Z',
              updated_at: '2022-04-27T07:59:29.756Z',
              path: 'ccb498ecefa0cfba8958570428598bd1/Raleigh.jpg',
            },
          },
        ],
      },
      TestimonialsBlock: {
        id: 1,
        description:
          'See why thousands of families in your area trust Seniorly to find their next home.',
        buttonText: 'Get started',
        cta: null,
        image: {
          id: 111,
          name: 'bottom-banner.jpg',
          alternativeText: '',
          caption: '',
          width: 708,
          height: 396,
          formats: null,
          hash: 'bottom_banner_15cf8affd4',
          ext: '.jpg',
          mime: 'image/jpeg',
          size: 49.75,
          url: 'https://seniorly.s3.us-west-1.amazonaws.com/uploads/4d2cc582f8a7fedfc0f58b4d9fcad99d/bottom-banner.jpg',
          previewUrl: null,
          provider: 'aws-s3-custom',
          provider_metadata: null,
          created_at: '2022-04-27T08:04:09.824Z',
          updated_at: '2022-04-27T08:04:09.838Z',
          path: '4d2cc582f8a7fedfc0f58b4d9fcad99d/bottom-banner.jpg',
        },
      },
    },
    meta: {},
  },
  status: 200,
  isJsonApi: false,
  error: false,
  isInvalid: false,
  hasFinished: true,
  hasFailed: false,
  headers: {},
  meta: {},
  endpoint: 'getHomePageInfo',
  paramsKey:
    '{"populate":["mainBlock","mainBlock.mainImg","mainBlock.headingImageList","mainBlock.headingImageList.image","mainBlock.cta","threeColumnWithButton","threeColumnWithButton.iconList","sectionReasons","sectionReasons.image","sectionReasons.ItemList","guides","guides.guidesList","quotesCarousel.carouselsList","CommunitiesByCity.imageList.image","TestimonialsBlock","TestimonialsBlock.image","TestimonialsBlock.cta","explorePopularCommunities","explorePopularCommunities.memory","explorePopularCommunities.assisted","explorePopularCommunities.independent"]}',
};

export default defaults;

export interface WrappedRawHomepageData {
  getHomePageInfo: {
    '{"populate":["mainBlock","mainBlock.mainImg","mainBlock.headingImageList","mainBlock.headingImageList.image","mainBlock.cta","threeColumnWithButton","threeColumnWithButton.iconList","sectionReasons","sectionReasons.image","sectionReasons.ItemList","guides","guides.guidesList","quotesCarousel.carouselsList","CommunitiesByCity.imageList.image","TestimonialsBlock","TestimonialsBlock.image","TestimonialsBlock.cta","explorePopularCities","explorePopularCities.memory","explorePopularCities.assisted","explorePopularCities.independent"]}': RawHomepageData;
  };
}

interface RawHomepageData {
  hasStarted: boolean;
  isLoading: boolean;
  body: Body;
  status: number;
  isJsonApi: boolean;
  error: boolean;
  isInvalid: boolean;
  hasFinished: boolean;
  hasFailed: boolean;
  headers?: Headers | Record<string, never>;
  meta?: Headers | Record<string, never>;
  endpoint: string;
  paramsKey: string;
  response?: Body;
  result?: Body;
}

interface Body {
  data: Result;
  meta?: Headers | Record<string, never>;
}
