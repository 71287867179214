import Section from '@react/shared/components/Section';

import { BodyProps } from './Body.types';

function Body({ children }: BodyProps) {
  return (
    <Section className='flex flex-col items-center py-m text-center'>
      {children}
    </Section>
  );
}

export default Body;
