import Guide from './Guide';
import { RightPaneProps } from './RightPane.types';

function RightPane({ guidesList }: RightPaneProps) {
  if (!guidesList)
    return <div data-tp-id='gen-div-5adbcca4-dbfa-4244-81e0-d93a3c9e4c31' />;
  return (
    <div
      className='lg:w-[31.5rem]'
      data-tp-id='gen-div-7ccfb181-7e83-49e0-b483-a1be15a7f236'
    >
      {guidesList.map((guide) => (
        <Guide key={guide.id} link={guide.link} title={guide.title} />
      ))}
    </div>
  );
}

export default RightPane;
