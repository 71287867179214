export default function Schema({
  metaDescription,
}: {
  metaDescription: string;
}) {
  const internalMetaDescription = JSON.stringify(metaDescription);
  const schema = `{
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://www.seniorly.com",
      "name": "Seniorly",
      "inLanguage": "en-US",
      "description": ${internalMetaDescription},
      "sameAs": "https://www.linkedin.com/company/seniorly/mycompany/",
      "author": {
        "@type": "Organization",
        "name": "Seniorly Inc."
      },
      "audience": {
        "@type": "Audience",
        "name": "Caregivers, Senior Care, Senior Housing, Senior Living, Assisted Living, Placement Agents, Elder care, Aging"
      },
      "contentLocation": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "San Francisco",
          "addressRegion": "CA"
        }
      }
    }`;

  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: schema }}
    ></script>
  );
}
