import QuoteIcon from 'sly/icons/Quote';

import { QuoteProps } from './Quote.types';

function opacityCalc(position: number) {
  switch (position) {
    case 0:
      return 'opacity-[1]';
    case 1:
      return 'opacity-[0.6]';
    case 2:
      return 'opacity-[0.3]';
    case 3:
      return 'opacity-[0.01]';
    case -1:
      return 'opacity-[0.6]';
    case -2:
      return 'opacity-[0.3]';
    case -3:
      return 'opacity-[0.01]';
  }
}

function topCalc(position: number) {
  switch (position) {
    case 0:
      return 'top-[32px]';
    case 1:
      return 'top-[16px]';
    case 2:
      return 'top-[0px]';
    case 3:
      return 'top-[-16px]';
    case -1:
      return 'top-[16px]';
    case -2:
      return 'top-[0px]';
    case -3:
      return 'top-[-16px]';
  }
}

function leftLargeCalc(position: number) {
  switch (position) {
    case 0:
      return 'left-[calc(50%-132px)]';
    case 1:
      return 'left-[calc(50%+280px-132px)]';
    case 2:
      return 'left-[calc(50%+560px-132px)]';
    case 3:
      return 'left-[calc(50%+840px-132px)]';
    case -1:
      return 'left-[calc(50%+-280px-132px)]';
    case -2:
      return 'left-[calc(50%+-560px-132px)]';
    case -3:
      return 'left-[calc(50%+-840px-132px)]';
  }
}

function leftTabletCalc(position: number) {
  switch (position) {
    case 0:
      return 'md:left-[calc(50%-189px)]';
    case 1:
      return 'md:left-[calc(50%+402px-189px)]';
    case 2:
      return 'md:left-[calc(50%+804px-189px)]';
    case 3:
      return 'md:left-[calc(50%+1206px-189px)]';
    case -1:
      return 'md:left-[calc(50%+-402px-189px)]';
    case -2:
      return 'md:left-[calc(50%+-804px-189px)]';
    case -3:
      return 'md:left-[calc(50%+-1206px-189px)]';
  }
}

function Quote({ position, isEdge, text, author }: QuoteProps) {
  const opacity = opacityCalc(position);
  const top = topCalc(position);
  const leftLarge = leftLargeCalc(position);
  const transition = isEdge ? '' : ' transition-[all_0.2s_ease-out_0s]';
  const variableClasses = `${opacity} ${top} ${leftLarge}${transition}`;
  const leftTablet = leftTabletCalc(position);
  const tabletClasses = `md:w-[378px] md:h-[487px] ${leftTablet}`;

  return (
    <div
      className={`absolute flex h-[452px] w-[264px] flex-col items-start rounded-lg bg-harvest-lighter-90 p-l ${variableClasses} ${tabletClasses}`}
      data-tp-id='gen-div-f06c7375-f89c-4211-8652-6348cf2ea2a4'
    >
      <QuoteIcon className='mb-6 h-xl w-xl text-harvest-base md:h-[48px] md:w-[48px]' />
      <p className='font-b-xl'>{text}</p>
      <div
        className='mt-auto'
        data-tp-id='gen-div-62d62c14-9039-4164-9b8c-ac3cbc603fce'
      >
        {author}
      </div>
    </div>
  );
}

export default Quote;
