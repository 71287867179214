import Button from 'sly/system/Button';
import Heading from 'sly/system/Heading';
import Link from 'sly/system/Link';

import { LeftPaneProps } from './LeftPane.types';

function LeftPane({ heading, title, buttonText, buttonLink }: LeftPaneProps) {
  return (
    <div
      className='mr-xxl mb-6 shrink-[20] grow-0'
      data-tp-id='gen-div-b7934bfd-013e-4851-84b4-885a38598656'
    >
      <Heading
        font='font-t-xl'
        className='mb-4'
        data-tp-id='gen-Heading-4d4c13a4-8e08-4a31-b4a7-070eef88ae9d'
      >
        {heading}
      </Heading>
      <div
        className='font-b-l mb-4'
        data-tp-id='gen-div-093ea0c7-7308-472a-8a15-f9afa08f2a74'
      >
        {title}
      </div>
      {buttonText && (
        <Link
          href={buttonLink || ''}
          event='Button Clicked'
          eventProps={{
            cta: 'Navigate to resource center',
            location: 'resources section',
            text: buttonText,
          }}
          data-tp-id='gen-Link-c3353078-f4b7-4e6e-bcd6-8e2678e39866'
        >
          <Button
            className='h-l'
            data-tp-id='gen-Button-33e27e3f-dc02-4410-955a-35bafccd5db7'
          >
            {buttonText}
          </Button>
        </Link>
      )}
    </div>
  );
}

export default LeftPane;
