import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0432 19.0418H16.0769V14.4006C16.0769 13.2937 16.0582 11.87 14.5357 11.87C12.9925 11.87 12.7569 13.0762 12.7569 14.3212V19.0418H9.79378V9.49747H12.6375V10.8025H12.6782C13.0738 10.0518 14.0413 9.2606 15.4844 9.2606C18.4882 9.2606 19.0432 11.2368 19.0432 13.8075V19.0418ZM6.44935 8.19372C5.49622 8.19372 4.7281 7.42309 4.7281 6.47309C4.7281 5.52372 5.49622 4.75309 6.44935 4.75309C7.3981 4.75309 8.16872 5.52372 8.16872 6.47309C8.16872 7.42309 7.3981 8.19372 6.44935 8.19372ZM7.9331 19.0419H4.96372V9.4975H7.9331V19.0419ZM21.1669 2H2.83375C2.37312 2 2 2.37313 2 2.83312V21.1663C2 21.6269 2.37312 22 2.83375 22H21.1669C21.6269 22 22 21.6269 22 21.1663V2.83312C22 2.37313 21.6269 2 21.1669 2Z"/>
</svg>`;

const Linkedin = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='linkedin' svg={svg} {...props} />
  )
);

Linkedin.displayName = 'LinkedinIcon';

export default Linkedin;
