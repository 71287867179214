import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.133 17.5536C9.28898 18.3119 8.22318 18.691 6.93562 18.691C5.44778 18.691 4.25322 18.1688 3.35193 17.1245C2.45064 16.0801 2 14.6996 2 12.9828C2 10.4936 2.8083 8.56223 4.42489 7.18884C6.04149 5.81545 8.36624 5.08584 11.3991 5V6.93133C9.73963 7.16023 8.49499 7.53219 7.66524 8.04721C6.83548 8.56223 6.34907 9.37768 6.20601 10.4936H8.00858C10.269 11.0372 11.3991 12.382 11.3991 14.5279C11.3991 15.7868 10.9771 16.7954 10.133 17.5536ZM20.7339 17.5536C19.8898 18.3119 18.824 18.691 17.5365 18.691C16.0486 18.691 14.8541 18.1688 13.9528 17.1245C13.0515 16.0801 12.6009 14.6996 12.6009 12.9828C12.6009 10.4936 13.4092 8.56223 15.0258 7.18884C16.6423 5.81545 18.9671 5.08584 22 5V6.93133C20.3405 7.16023 19.0959 7.53219 18.2661 8.04721C17.4363 8.56223 16.9499 9.37768 16.8069 10.4936H18.6094C20.8698 11.0372 22 12.382 22 14.5279C22 15.7868 21.578 16.7954 20.7339 17.5536Z"/>
</svg>`;

const Quote = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='quote' svg={svg} {...props} />
  )
);

Quote.displayName = 'QuoteIcon';

export default Quote;
