import Separator from '@react/shared/components/Separator';
import { useCallback, useMemo, useState } from 'react';
import QuoteControls from 'sly/homePage/components/QuotesCarousel/QuoteControls';
import Heading from 'sly/system/Heading';

import Quote from './Quote';
import { QuotesCarouselProps } from './QuotesCarousel.types';

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

function QuotesCarousel({ quotes }: QuotesCarouselProps) {
  const [center, setCenter] = useState(0);
  const { carouselsList = [], heading } = quotes;
  const [below, above] = useMemo(() => {
    const memoAbove = Math.ceil(carouselsList.length / 2);
    const memoBelow = carouselsList.length - memoAbove;
    return [memoBelow, memoAbove];
  }, [carouselsList]);
  const move = useCallback(
    (reverse = false) => {
      setCenter(center + (reverse ? -1 : 1));
    },
    [center]
  );

  if (!carouselsList.length) return null;

  return (
    <>
      <div
        className='mb-xxl md:mb-[64px] lg:mb-xxxl'
        data-tp-id='gen-div-070ca316-0bc3-4768-ad54-cdeedd3ae4eb'
      >
        <Heading
          font='font-t-xl'
          className='my-0 mx-auto max-w-[450px] text-center'
          data-tp-id='gen-Heading-762446cc-b149-4f1c-8f6f-c626ef89d7d3'
        >
          {heading}
        </Heading>
        <div
          className='relative mb-l h-[485px] overflow-hidden md:h-[520px]'
          data-tp-id='gen-div-788f2a7a-b743-4cfc-bedb-f84e5f994b7b'
        >
          {carouselsList.map(({ id, author, text }, index, innerQuotes) => {
            const iCenter = mod(index + center, innerQuotes.length);
            const isEdge =
              iCenter < above
                ? iCenter === above - 1
                : innerQuotes.length - iCenter === below;
            const position =
              iCenter < above ? iCenter : iCenter - innerQuotes.length;
            return (
              <Quote
                key={id}
                position={position}
                isEdge={isEdge}
                text={text}
                author={author}
              />
            );
          })}
        </div>
        <QuoteControls move={move} />
      </div>
      <Separator />
    </>
  );
}

export default QuotesCarousel;
