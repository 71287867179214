/** @jsxImportSource react */
import { STRAPI_CTA_TYPES } from '@sly/core/constants/strapi';
import dynamic from 'next/dynamic';
import React from 'react';

const AssesmentWizardCta = dynamic(
  /* #__LOADABLE__ */ () =>
    import(
      /* webpackChunkName: "chunkTypeformCta" */ '@react/cta/AssessmentWizardCta'
    )
);
const TypeformCta = dynamic(
  /* #__LOADABLE__ */ () =>
    import(/* webpackChunkName: "chunkTypeformCta" */ '@react/cta/TypeformCta')
);
const BotCta = dynamic(
  /* #__LOADABLE__ */ () =>
    import(/* webpackChunkName: "chunkBotCta" */ '@react/cta/BotCta')
);
const PathCta = dynamic(
  /* #__LOADABLE__ */ () =>
    import(/* webpackChunkName: "chunkPathCta" */ '@react/cta/PathCta')
);
const ExpertHelpCta = dynamic(
  /* #__LOADABLE__ */ () =>
    import(
      /* webpackChunkName: "chunkExpertHelpCta" */ '@react/cta/ExpertHelpCta'
    )
);

const GwizzyCta = dynamic(() =>
  import(/* webpackChunkName: "chunkGwizzyCta" */ '@react/cta/GwizzyCta')
);

const Cta = ({ ctaProps, ...props }) => {
  const { type } = ctaProps;

  switch (type) {
    case STRAPI_CTA_TYPES.typeform_wizard:
      return <TypeformCta ctaProps={ctaProps} {...props} />;
    case STRAPI_CTA_TYPES.assessmentWizard:
      return <AssesmentWizardCta ctaProps={ctaProps} {...props} />;
    case STRAPI_CTA_TYPES.getExpertHelp:
      return <ExpertHelpCta ctaProps={ctaProps} {...props} />;
    case STRAPI_CTA_TYPES.bot:
      return <BotCta ctaProps={ctaProps} {...props} />;
    case STRAPI_CTA_TYPES.path:
      return <PathCta ctaProps={ctaProps} {...props} />;
    case STRAPI_CTA_TYPES.gwizzy:
      return <GwizzyCta ctaProps={ctaProps} {...props} />;

    default:
      throw new Error(`Not a valid CTA type ${type}`);
  }
};

export default Cta;
