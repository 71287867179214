import Chevron from 'sly/icons/Chevron';
import Link from 'sly/system/Link';

import { GuideProps } from './Guide.types';

function Guide({ link, title }: GuideProps) {
  return (
    <Link
      href={link}
      className='font-t-s-azo mb-m flex items-center rounded-lg bg-white p-l hover:shadow-[rgb(0,0,0_0.06)_0_0.25rem_1rem]'
      eventProps={{
        text: title,
        location: 'resources section',
      }}
      data-tp-id='gen-Link-c8f81fed-0e88-4568-a48d-fe8b6250d60e'
    >
      <span
        className='grow'
        data-tp-id='gen-span-25e4d9a5-ca76-4f9e-9760-fed06fe4a34a'
      >
        {title}
      </span>
      <Chevron size='l' className='mb-0 rotate-90' />
    </Link>
  );
}

export default Guide;
