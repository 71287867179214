import { default as Image } from '@react/shared/components/Image';
import { leadSourceMap } from '@sly/core/constants/uuidActions';
import Cta from '@sly/frontend/react/cta/Cta';
import { getEventProps } from '@sly/frontend/react/cta/helpers/getEventProps';
import { useRouter } from 'next/router';
import { useState } from 'react';
import {
  QuestionBoxProps,
  TripleQuestionTypes,
} from 'sly/homePage/components/TripleQuestion/TripleQuestion.types';
import TripleQuestionModal from 'sly/homePage/components/TripleQuestion/TripleQuestionModal';
import { assetPath } from 'sly/lib/path';
import { Button } from 'sly/system';
import Heading from 'sly/system/Heading';

const defaultsElement = [
  {
    id: 5,
    icon: 'advisor',
    title: 'Your Own Advisor',
    value:
      '<p>We connect you with a Seniorly Local Advisor, our trusted partner who knows the communities in your area. Rely on your advisor as much or as little as you need to find a new home you’ll love.</p>',
    buttonColor: 'yellow',
    buttonText: 'Speak with an expert',
    cta: {
      id: 45,
      type: 'assessment_wizard',
      name: null,
      path: null,
      text: 'Speak with an expert',
      prop: null,
      variant: 'primary',
      palette: 'yellow',
    },
  },
  {
    id: 6,
    icon: 'smart_search',
    title: 'Our Smart Search ',
    value:
      '<p>Take our short quiz to set your personal preferences, then see the communities we recommend for you. Seniorly Smart Search helps you make sense of your options and choose wisely.</p>',
    buttonColor: 'viridian',
    buttonText: 'Take our quiz',
    cta: {
      id: 46,
      type: 'assessment_wizard',
      name: null,
      path: null,
      text: 'Take our quiz',
      prop: null,
      variant: 'primary',
      palette: 'viridian',
    },
  },
  {
    id: 7,
    icon: 'freedom_to_explore',
    title: 'Freedom to Explore',
    value:
      '<p>Want to explore communities on your own? No problem. We give you the tools to navigate through over 60,000 of the best communities—with access to pricing and exclusive photos.</p>',
    buttonColor: 'harvest',
    buttonText: 'Explore communities',
    cta: {
      id: 47,
      type: 'assessment_wizard',
      name: null,
      path: null,
      text: 'Explore Communities',
      prop: null,
      variant: 'primary',
      palette: 'harvest',
    },
  },
];

function QuestionBox({ element, index, onButtonClick }: QuestionBoxProps) {
  const indexToMode = {
    0: {
      cta: 'speakExpert',
      entry: 'homepage',
      leadSource: leadSourceMap.HomepageSpeakWithExpertLS,
    },
    1: {
      cta: 'generalOptions',
      entry: 'homepage',
      leadSource: leadSourceMap.HomepageQuizLS,
    },
    2: undefined,
  };
  if (!element.cta) {
    element.cta = defaultsElement[index].cta;
  }

  const innerOnButtonClick = () => {
    onButtonClick();
  };

  const { query } = useRouter();

  const { eventProps } = getEventProps(element.cta, {}) || {};

  return (
    <div
      className='rounded-xs basis-[20.5rem] bg-harvest-lighter-90 p-l pt-xxl text-center'
      data-tp-id='gen-div-893a1a34-e854-44a0-bef0-359852a28906'
    >
      <div
        className='flex h-full flex-col items-center justify-between'
        data-tp-id='gen-div-41fd368a-671a-4cc5-ac37-7f2513f5587d'
      >
        <Image
          src={
            element.icon
              ? assetPath(`vectors/home/${element.icon.replace(/_/g, '-')}.svg`)
              : assetPath('vectors/home/advisor.svg')
          }
          alt={`icon${index}`}
          width={60}
          height={52}
          unoptimized
          data-tp-id='gen-Image-d79d06ca-90d9-421a-8d51-ae6bddfc9422'
        />
        <Heading
          className='font-t-m mb-4 p-4 text-center'
          data-tp-id='gen-Heading-6a4b2504-5130-41e9-bb02-b99f9feb6a12'
        >
          {element.title}
        </Heading>
        <div
          className='font-b-l mb-6'
          data-tp-id='gen-div-91e0a4de-ee20-45da-9907-813516655a96'
        >
          {element.value.replace('<p>', '').replace('</p>', '')}
        </div>
        <div
          className='flex items-center child:w-full md:w-full'
          data-tp-id='gen-div-aff78a36-a607-47ec-8d28-a60a243d3369'
        >
          {index === 2 ? (
            <Button
              className='w-full align-middle'
              palette={element.cta?.palette}
              // to={toOnIndex[index as keyof typeof toOnIndex]}
              onClick={index === 2 ? innerOnButtonClick : undefined}
              eventProps={{
                cta: 'Open location search',
                location: 'freedomToExploreSearch',
              }}
              data-tp-id='gen-Button-ed081d87-07ff-48c1-b181-1ed31728f155'
            >
              {element.cta?.text}
            </Button>
          ) : (
            <Cta
              showLocationStep={true}
              params={query}
              ctaProps={element.cta}
              mode={indexToMode[index as keyof typeof indexToMode]}
              className='!w-full'
              startLink='/wizards/assessment'
              eventProps={{
                ...eventProps,
                entry: `highlightSection${index}`,
                location: element?.cta?.type,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function TripleQuestion({ title, iconList }: TripleQuestionTypes) {
  const [showModal, setShowModal] = useState(false);
  const onButtonClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <TripleQuestionModal showModal={showModal} setShowModal={setShowModal} />
      <section className='relative mx-auto mb-xxl flex flex-col px-l md:mb-[64px] md:w-[var(--col8)] lg:mb-xxxl lg:w-[64rem]'>
        <Heading
          font='font-t-xl'
          className='font-t-xl mx-auto mb-l max-w-[var(--col8)] text-center'
          data-tp-id='gen-Heading-5652d112-8af3-4d91-8a0c-2176fc00f878'
        >
          {title}
        </Heading>
        <div
          className='grid grid-cols-[auto] gap-l lg:grid-cols-[var(--col4)_var(--col4)_var(--col4)]'
          data-tp-id='gen-div-a2850ad7-c233-4f69-b758-6fa5ded9afb6'
        >
          {iconList &&
            iconList.map((element, index) => (
              <QuestionBox
                key={index}
                element={element}
                index={index}
                onButtonClick={onButtonClick}
              />
            ))}
        </div>
      </section>
    </>
  );
}

export default TripleQuestion;
