import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export interface getDimensionObjectProps {
  el: HTMLDivElement;
}

export function getDimensionObject({ el }: getDimensionObjectProps) {
  const rect = el.getBoundingClientRect();

  const x = rect.x;
  const y = rect.y;

  return {
    width: rect.width,
    height: rect.height,
    left: x,
    top: y,
    x,
    y,
    right: rect.right,
    bottom: rect.bottom,
    clientWidth: el.clientWidth,
    clientHeight: el.clientHeight,
  };
}

function useDimensions() {
  const [dimensions, setDimensions] = useState({
    bottom: 0,
    clientHeight: 0,
    clientWidth: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const ref = useRef<HTMLDivElement>(null);

  const onResize = useMemo(
    () =>
      debounce(() => {
        if (ref.current) {
          setDimensions(getDimensionObject({ el: ref.current }));
        }
      }, 150),
    [ref]
  );

  useResizeObserver({ ref, onResize });

  return { ref, dimensions };
}

export default useDimensions;
