import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12C23 5.92486 18.0751 1 12 1C5.92486 1 1 5.92486 1 12C1 17.4904 5.02254 22.0412 10.2812 22.8664V15.1797H7.48828V12H10.2812V9.57656C10.2812 6.81969 11.9235 5.29688 14.4361 5.29688C15.6396 5.29688 16.8984 5.51172 16.8984 5.51172V8.21875H15.5114C14.1449 8.21875 13.7188 9.06667 13.7188 9.93658V12H16.7695L16.2818 15.1797H13.7188V22.8664C18.9775 22.0412 23 17.4904 23 12Z"/>
</svg>`;

const Facebook = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='facebook' svg={svg} {...props} />
  )
);

Facebook.displayName = 'FacebookIcon';

export default Facebook;
