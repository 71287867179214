import Footer from '@react/shared/components/Footer';
import Header from '@react/shared/components/Header/Header.container';
import { default as Image } from '@react/shared/components/Image';
import Separator from '@react/shared/components/Separator';
import { populate } from '@sly/core/constants/populate';
import get from 'lodash/get';
import Helmet from 'next/head';
import { useRouter } from 'next/router';
import { StrictMode, useEffect, useMemo } from 'react';
import { host } from 'sly/config';
import CommunitiesByCity from 'sly/homePage/components/CommunitiesByCity';
import GetStarted from 'sly/homePage/components/GetStarted';
import Guides from 'sly/homePage/components/Guides';
import HomePageHeader from 'sly/homePage/components/HomePageHeader';
import QuotesCarousel from 'sly/homePage/components/QuotesCarousel';
import Reasons from 'sly/homePage/components/Reasons';
import TripleQuestion from 'sly/homePage/components/TripleQuestion';
import ExploreByCommunity, {
  TabList,
} from 'sly/hubPage/components/HubDynamicContent/ExploreByEntity';
import { assetPath } from 'sly/lib/path';
import { usePrefetch } from 'sly/services/api';
import { pageNames, useEvents } from 'sly/services/events';

import { MainBlock, Result, ThreeColumnWithButton } from './HomePage.types';
import Schema from './Schema';
import ExploreByCity from '../ExploreByCity';
import defaults from '../../../../pagesDefaults/homepage';

const getObj = (obg: Result, value: string) => get(obg, `${value}`) || {};
const getArr = (obg: Result, value: string) => get(obg, `${value}`) || [];
const metaDescription =
  'Find the best assisted living and other senior living like memory care, respite care, CCRC, nursing homes, independent living and more.';

function HomePage() {
  const { events } = useEvents();
  const router = useRouter();
  useEffect(() => {
    events.page(pageNames.Home, {});
    // if (result?.chatbotAutoload !== false) {
    //   triggerChatBot(
    //     result?.chatbotAutoloadName || GENERIC_CHAT_BOT_TRIGGER,
    //     result?.chatbotAutoloadTime || 25000
    //   );
    // }
  }, []);

  const { requestInfo } = usePrefetch('getHomePageInfo', {
    populate: populate.homePage,
  });
  const {
    result: strapiResponse,
    hasFinished,
  }: { result: { data: Result }; hasFinished: boolean } = requestInfo;
  const result = strapiResponse?.data;
  const mainBlock: MainBlock = useMemo(
    () => getObj(result, 'mainBlock'),
    [result]
  );
  const threeColumnWithButton: ThreeColumnWithButton = useMemo(
    () => getObj(result, 'threeColumnWithButton'),
    [result]
  );
  const sectionReasons = useMemo(
    () => getArr(result, 'sectionReasons'),
    [result]
  );
  const communitiesByCity = useMemo(
    () => getObj(result, 'CommunitiesByCity'),
    [result]
  );
  const guides = useMemo(() => getObj(result, 'guides'), [result]);
  const quotesCarousel = useMemo(
    () => getObj(result, 'quotesCarousel'),
    [result]
  );
  const testimonialsBlock = useMemo(
    () => getObj(result, 'TestimonialsBlock'),
    [result]
  );

  const communitiesList: TabList = useMemo(
    () => getArr(result, 'communitiesList'),
    [result]
  );
  const { heading, mainImg, title, headingImageList } = mainBlock;
  let { cta } = mainBlock;
  // TODO add CTA to CMS
  if (!cta) {
    cta = defaults.body.data.mainBlock.cta;
  }
  const { path } = mainImg || '';
  const { iconList, heading: listTitle } = threeColumnWithButton;
  const { image, description, cta: testimonialCta } = testimonialsBlock;

  const { path: pathImg } = image || '';
  if (!hasFinished) {
    return (
      <div
        className='flex h-[100vh] items-center justify-center'
        data-tp-id='gen-div-feaa6e67-5c5d-4d89-90dd-ce5a7a880321'
      >
        <Image
          src={assetPath('uploads/react-assets/homebase/loader.svg')}
          alt='Loading...'
          width={200}
          height={200}
          data-tp-id='gen-Image-aac57ad8-b674-48a9-9bd4-ee8bf4780da1'
        />
      </div>
    );
  }

  return (
    <StrictMode>
      <Helmet>
        <title>
          Assisted Living Cost &amp; Reviews | Senior Living Advisors | Seniorly
        </title>
        <meta name='description' content={metaDescription} />
        <link rel='canonical' href={`${host}${router.pathname}`} />
        <Schema metaDescription={metaDescription} />
      </Helmet>
      <header className='relative'>
        <Header showHeaderPhNo showHeaderAgentPhNo />
      </header>
      <HomePageHeader
        heading={heading}
        path={path}
        title={title}
        cta={cta}
        headingImageList={headingImageList}
      />
      <TripleQuestion title={listTitle} iconList={iconList} />
      <Separator />
      <Reasons sectionReasons={sectionReasons} />
      <Separator />
      <CommunitiesByCity communitiesByCity={communitiesByCity} />
      <Guides guides={guides} />
      <QuotesCarousel quotes={quotesCarousel} />
      <ExploreByCommunity
        entity='communities'
        title={communitiesList?.title}
        tabList={communitiesList?.tabList}
      />
      <ExploreByCity popularCities={result?.explorePopularCities} />
      <GetStarted
        description={description}
        pathImg={pathImg}
        cta={
          testimonialCta || {
            type: 'gwizzy',
            text: 'Get started',
            prop: 'homebase',
            variant: 'primary',
            palette: 'viridian',
            contactInfo: 'email_and_phone',
            enableOneToMany: true,
          }
        }
      />
      <Footer className='' />
    </StrictMode>
  );
}

export default HomePage;
