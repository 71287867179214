export const populate = {
  homePage: [
    'mainBlock',
    'mainBlock.mainImg',
    'mainBlock.headingImageList',
    'mainBlock.headingImageList.image',
    'mainBlock.cta',
    'threeColumnWithButton',
    'threeColumnWithButton.iconList',
    'threeColumnWithButton.iconList.cta',
    'sectionReasons',
    'sectionReasons.image',
    'sectionReasons.ItemList',
    'guides',
    'guides.guidesList',
    'quotesCarousel.carouselsList',
    'CommunitiesByCity.imageList.image',
    'TestimonialsBlock',
    'TestimonialsBlock.image',
    'TestimonialsBlock.cta',
    'explorePopularCities',
    'explorePopularCities.memory',
    'explorePopularCities.assisted',
    'explorePopularCities.independent',
    'communitiesList',
    'communitiesList.tabList'
  ],
  hubPage: [
    'mainImg',
    'content',
    'content.video',
    'content.value',
    'content.tagsList',
    'content.image',
    'content.advisors',
    'content.communities',
    'content.link',
    'reviewer',
    'author',
    'author.img',
    'iframeContent',
    'content.communitiesList',
    'content.tabList'
  ],
  marketingPage: [
    'mainImage',
    'MarketingPageDz',
    'MarketingPageDz.recentArticles',
    'MarketingPageDz.recentArticles.mainTopic',
    'MarketingPageDz.articles',
    'MarketingPageDz.articles.mainImg',
    'MarketingPageDz.linksBlock',
    'MarketingPageDz.linksBlock.linksList',
    'MarketingPageDz.imgWithTitle.srcImg',
    'MarketingPageDz.RepeteableComponent',
    'MarketingPageDz.IconList',
    'MarketingPageDz.FaqItem',
    'MarketingPageDz.link',
    'MarketingPageDz.tagsList',
    'MarketingPageDz.communitiesList',
    'MarketingPageDz.articlesList',
    'MarketingPageDz.tabList',
    'MarketingPageDz.communities',
    'MarketingPageDz.advisors',
    'MarketingPageDz.tabList',
    'reviewer',
    'iframeContent'
  ],
  articlePage: [
    'mainImg',
    'content.image',
    'content.advisors',
    'content.communities',
    'content.value',
    'content.link',
    'content.video',
    'author.img',
    // 'author.articles.mainImg',
    // 'author.articles.content.image',
    // 'author.articles.content.advisors',
    // 'author.articles.content.communities',
    // 'author.articles.content.value',
    // 'author.articles.content.link',
    // 'author.articles.content.video',
    // 'author.articles.author.img',
    // 'author.articles.author.articles',
    // 'author.articles.tagsList',
    // "author.articles.mainTopic.img"
    // "author.articles.mainTopic.articles"
    // 'author.articles.threeArticlesInTheBottom.firstArticle',
    // 'author.articles.threeArticlesInTheBottom.secondArticle',
    // 'author.articles.threeArticlesInTheBottom.thirdArticle',
    'tagsList',
    'mainTopic',
    // "mainTopic.img"
    // "mainTopic.articles.mainImg"
    // "mainTopic.articles.content.image"
    // "mainTopic.articles.content.advisors"
    // "mainTopic.articles.content.communities"
    // "mainTopic.articles.content.value"
    // "mainTopic.articles.content.link"
    // "mainTopic.articles.content.video"
    // "mainTopic.articles.author.img"
    // "mainTopic.articles.author.articles"
    // "mainTopic.articles.author.createdBy"
    // "mainTopic.articles.author.updatedBy"
    // "mainTopic.articles.tagsList",
    // "mainTopic.articles.mainTopic.img"
    // "mainTopic.articles.mainTopic.articles"
    // "mainTopic.articles.mainTopic.createdBy"
    // "mainTopic.articles.mainTopic.updatedBy"
    // "mainTopic.articles.threeArticlesInTheBottom.firstArticle"
    // "mainTopic.articles.threeArticlesInTheBottom.secondArticle"
    // "mainTopic.articles.threeArticlesInTheBottom.thirdArticle"
    'threeArticlesInTheBottom.firstArticle.mainImg',
    'threeArticlesInTheBottom.firstArticle.tagsList',
    'threeArticlesInTheBottom.secondArticle.mainImg',
    'threeArticlesInTheBottom.secondArticle.tagsList',
    // "threeArticlesInTheBottom.secondArticle.mainTopic.img",
    'threeArticlesInTheBottom.thirdArticle.mainImg',
    // "threeArticlesInTheBottom.thirdArticle.content.image",
    // "threeArticlesInTheBottom.thirdArticle.content.advisors",
    // "threeArticlesInTheBottom.thirdArticle.content.communities",
    // "threeArticlesInTheBottom.thirdArticle.content.value",
    // "threeArticlesInTheBottom.thirdArticle.content.link",
    // "threeArticlesInTheBottom.thirdArticle.content.video",
    // "threeArticlesInTheBottom.thirdArticle.author.img",
    // "threeArticlesInTheBottom.thirdArticle.author.articles",
    // "threeArticlesInTheBottom.thirdArticle.author.createdBy",
    // "threeArticlesInTheBottom.thirdArticle.author.updatedBy",
    'threeArticlesInTheBottom.thirdArticle.tagsList',
    // "threeArticlesInTheBottom.thirdArticle.mainTopic.img",
    // "threeArticlesInTheBottom.thirdArticle.mainTopic.articles",
    // "threeArticlesInTheBottom.thirdArticle.mainTopic.createdBy",
    // "threeArticlesInTheBottom.thirdArticle.mainTopic.updatedBy",
    // "threeArticlesInTheBottom.thirdArticle.threeArticlesInTheBottom.firstArticle",
    // "threeArticlesInTheBottom.thirdArticle.threeArticlesInTheBottom.secondArticle",
    // "threeArticlesInTheBottom.thirdArticle.threeArticlesInTheBottom.thirdArticle",
    'worksConsulted',
    'reviewer',
    'iframeContent'
  ],
  providerPage: [
    'faqs',
    'similarProviderNearby',
    'similarProviderNearby.links',
    'popularCommunities',
    'popularCommunities.links',
    'logo'
  ],
  statePage: [
    'mainImg',
    'content',
    'content.link',
    'content.tagsList',
    'content.image',
    'content.advisors',
    'content.communities',
    'content.value',
    'content.video'
  ],
  authors: ['img'],
  reviewers: ['img']
};
// statePage
// {
//   content: {
//     populate: {
//       link: {
//       },
//       tagsList: {
//       },
//       video: true,
//       image: true,
//       value: {
//       },
//       advisors: {
//       },
//       communities: {
//       },
//     },
//   },
//   mainImg: true,
// }

// {
//   faqs: {
//   },
//   similarProviderNearby: {
//     populate: {
//       links: {
//       },
//     },
//   },
//   popularCommunities: {
//     populate: {
//       links: {
//       },
//     },
//   },
// }

// {
//   populate: {
//     mainImg: true,
//     content: {
//       populate: {
//         link: {
//         },
//         tagsList: {
//         },
//         image: true,
//         value: {
//         },
//         advisors: {
//         },
//         communities: {
//         },
//         video: true,
//       },
//     },
//   },
// }
/*

{
  populate: {
    MarketingPageDz: {
      populate: {
        articles: {
          populate: {
            mainImg
            content: {
              populate: {
                image: true,
                advisors: true,
                communities: true,
                value: true,
                link: true,
                video: true,
              },
            },
            author: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            tagsList: {
            },
            mainTopic: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            threeArticlesInTheBottom: {
              populate: {
                firstArticle: true,
                secondArticle: true,
                thirdArticle: true,
              },
            },
          },
        },
        link: {
        },
        tagsList: {
        },
        image: true,
        communities: {
        },
        video: true,
        value: {
        },
        advisors: {
        },
        firstArticle: {
          populate: {
            mainImg: true,
            content: {
              populate: {
                image: true,
                advisors: true,
                communities: true,
                value: true,
                link: true,
                video: true,
              },
            },
            author: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            tagsList: {
            },
            mainTopic: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            threeArticlesInTheBottom: {
              populate: {
                firstArticle: true,
                secondArticle: true,
                thirdArticle: true,
              },
            },
          },
        },
        secondArticle: {
          populate: {
            mainImg: true,
            content: {
              populate: {
                image: true,
                advisors: true,
                communities: true,
                value: true,
                link: true,
                video: true,
              },
            },
            author: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            tagsList: {
            },
            mainTopic: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            threeArticlesInTheBottom: {
              populate: {
                firstArticle: true,
                secondArticle: true,
                thirdArticle: true,
              },
            },
          },
        },
        thirdArticle: {
          populate: {
            mainImg: true,
            content: {
              populate: {
                image: true,
                advisors: true,
                communities: true,
                value: true,
                link: true,
                video: true,
              },
            },
            author: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            tagsList: {
            },
            mainTopic: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            threeArticlesInTheBottom: {
              populate: {
                firstArticle: true,
                secondArticle: true,
                thirdArticle: true,
              },
            },
          },
        },
        imgWithTitle: {
          populate: {
            srcImg: true,
          },
        },
        Image: true,
        FaqItem: {
        },
        RepeteableComponent: {
        },
        IconList: {
        },
      },
    },
    mainImage: true,
  },
}


{
  populate: {
    mainImg: true,
    content: {
      populate: {
        image: true,
        advisors: {
        },
        communities: {
        },
        value: {
        },
        link: {
        },
        video: true,
      },
    },
    author: {
      populate: {
        img: true,
        articles: {
          populate: {
            mainImg: true,
            content: {
              populate: {
                image: true,
                advisors: true,
                communities: true,
                value: true,
                link: true,
                video: true,
              },
            },
            author: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            tagsList: {
            },
            mainTopic: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            threeArticlesInTheBottom: {
              populate: {
                firstArticle: true,
                secondArticle: true,
                thirdArticle: true,
              },
            },
          },
        },
      },
    },
    tagsList: {
    },
    mainTopic: {
      populate: {
        img: true,
        articles: {
          populate: {
            mainImg: true,
            content: {
              populate: {
                image: true,
                advisors: true,
                communities: true,
                value: true,
                link: true,
                video: true,
              },
            },
            author: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            tagsList: {
            },
            mainTopic: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            threeArticlesInTheBottom: {
              populate: {
                firstArticle: true,
                secondArticle: true,
                thirdArticle: true,
              },
            },
          },
        },
      },
    },
    threeArticlesInTheBottom: {
      populate: {
        firstArticle: {
          populate: {
            mainImg: true,
            content: {
              populate: {
                image: true,
                advisors: true,
                communities: true,
                value: true,
                link: true,
                video: true,
              },
            },
            author: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            tagsList: {
            },
            mainTopic: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            threeArticlesInTheBottom: {
              populate: {
                firstArticle: true,
                secondArticle: true,
                thirdArticle: true,
              },
            },
          },
        },
        secondArticle: {
          populate: {
            mainImg: true,
            content: {
              populate: {
                image: true,
                advisors: true,
                communities: true,
                value: true,
                link: true,
                video: true,
              },
            },
            author: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            tagsList: {
            },
            mainTopic: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            threeArticlesInTheBottom: {
              populate: {
                firstArticle: true,
                secondArticle: true,
                thirdArticle: true,
              },
            },
          },
        },
        thirdArticle: {
          populate: {
            mainImg: true,
            content: {
              populate: {
                image: true,
                advisors: true,
                communities: true,
                value: true,
                link: true,
                video: true,
              },
            },
            author: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            tagsList: {
            },
            mainTopic: {
              populate: {
                img: true,
                articles: true,
                createdBy: true,
                updatedBy: true,
              },
            },
            threeArticlesInTheBottom: {
              populate: {
                firstArticle: true,
                secondArticle: true,
                thirdArticle: true,
              },
            },
          },
        },
      },
    },
  },
}
*/
