import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M2.93425 19.4783C2.61504 19.4783 2.34564 19.3685 2.12608 19.1489C1.90651 18.9294 1.79674 18.66 1.79675 18.3408C1.79675 18.0216 1.90654 17.7522 2.1261 17.5326C2.34567 17.3131 2.61506 17.2033 2.93428 17.2033H21.0658C21.385 17.2033 21.6544 17.3131 21.874 17.5326C22.0935 17.7522 22.2033 18.0216 22.2033 18.3408C22.2033 18.66 22.0935 18.9294 21.874 19.1489C21.6544 19.3685 21.385 19.4783 21.0658 19.4783H2.93425ZM2.79673 16.2033V15C2.79673 12.8547 3.4489 10.9515 4.75325 9.29025C6.0576 7.62901 7.73876 6.56379 9.79673 6.09457V5.89242C9.79673 5.28662 10.0125 4.76796 10.444 4.33642C10.8755 3.90491 11.3942 3.68915 12 3.68915C12.6058 3.68915 13.1245 3.90491 13.556 4.33642C13.9875 4.76796 14.2033 5.28662 14.2033 5.89242V6.09457C16.2779 6.56379 17.9632 7.62901 19.2593 9.29025C20.5553 10.9515 21.2033 12.8547 21.2033 15V16.2033H2.79673ZM5.13968 13.9283H18.8603C18.615 12.2268 17.843 10.8254 16.5443 9.72395C15.2456 8.6225 13.7308 8.07177 12 8.07177C10.2692 8.07177 8.75761 8.6225 7.46523 9.72395C6.17283 10.8254 5.39764 12.2268 5.13968 13.9283Z"/>
</svg>`;

const Concierge = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='concierge' svg={svg} {...props} />
  )
);

Concierge.displayName = 'ConciergeIcon';

export default Concierge;
