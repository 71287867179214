import Tab from '@react/shared/components/Tab';
import Tabs from '@react/shared/components/Tabs';
import { MouseEvent, useState } from 'react';
import ExploreByCitySection from 'sly/homePage/components/ExploreByCity/ExploreByCitySection';
import { ExplorePopularCities } from 'sly/homePage/components/HomePage/HomePage.types';
import Heading from 'sly/system/Heading';

export default function ExploreByCity({
  popularCities,
}: {
  popularCities?: ExplorePopularCities;
}) {
  const tabs = ['Assisted Living', 'Memory Care', 'Independent Living'];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  if (!popularCities) return null;
  const { assisted, independent, memory } = popularCities;
  const popularCitiesDict = {
    'Assisted Living': assisted || [],
    'Independent Living': independent || [],
    'Memory Care': memory || [],
  };
  if (
    popularCitiesDict['Assisted Living'].length === 0 &&
    popularCitiesDict['Independent Living'].length === 0 &&
    popularCitiesDict['Memory Care'].length === 0
  ) {
    return null;
  }
  const onClick = (e: MouseEvent<HTMLSpanElement>) => {
    setActiveTab((e.target as Element).id);
  };
  return (
    <div className='px-m md:mx-6 md:px-0 lg:mx-0'>
      <Heading
        font='font-t-xl'
        className='my-0 mx-auto mb-4 w-full md:w-[720px] lg:w-[1032px]'
        data-tp-id='gen-Heading-762446cc-b149-4f1c-8f6f-c626ef89d7d3'
      >
        Explore senior living communities by city
      </Heading>
      <div className='mx-auto w-full md:w-[720px] lg:w-[1032px]'>
        <Tabs activeTab={activeTab} className='px-0'>
          {tabs.map((tab) => {
            if (
              !popularCitiesDict[tab as keyof typeof popularCitiesDict] ||
              !popularCitiesDict[tab as keyof typeof popularCitiesDict].length
            )
              return undefined;
            return (
              <Tab
                key={tab}
                id={tab}
                onClick={onClick}
                className='hover:cursor-pointer'
              >
                {tab}
              </Tab>
            );
          })}
        </Tabs>
      </div>
      <div>
        {tabs.map((tab) => {
          if (
            !popularCitiesDict[tab as keyof typeof popularCitiesDict] ||
            !popularCitiesDict[tab as keyof typeof popularCitiesDict].length
          )
            return null;
          return (
            <ExploreByCitySection
              popularCitiesForSection={
                popularCitiesDict[
                  tab as keyof typeof popularCitiesDict
                ] as string
              }
              key={tab}
              active={activeTab === tab}
              tab={tab}
            />
          );
        })}
      </div>
    </div>
  );
}
