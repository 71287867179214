import Cta from '@sly/frontend/react/cta/Cta';
import { getEventProps } from '@sly/frontend/react/cta/helpers/getEventProps';
import { useRouter } from 'next/router';

import { GettingStartedHalfProps } from './GettingStartedHalf.types';

function GettingStartedHalf({ description, cta }: GettingStartedHalfProps) {
  const { query } = useRouter();
  const { eventProps } = getEventProps(cta, {}) || {};
  return (
    <div
      className='flex basis-1/2 items-center justify-center bg-viridian-base'
      data-tp-id='gen-div-075473d6-88c9-45cf-ba7c-d45cb6c03625'
    >
      <div
        className='w-auto px-4 pb-6 md:w-[480px]'
        data-tp-id='gen-div-27520b9b-272b-4eb5-afea-838277af22d5'
      >
        <div
          className='font-t-m py-l text-center text-white md:text-left'
          data-tp-id='gen-div-837a19d1-235c-48cc-b43b-a6e0c4aa94a9'
        >
          {description}
        </div>
        {cta && (
          <Cta
            showLocationStep={true}
            params={query}
            ctaProps={cta}
            mode={{ cta: 'speakExpert', entry: 'homepage' }}
            className='border-2 border-white md:max-w-max'
            startLink='/wizards/assessment'
            eventProps={{
              ...eventProps,
              entry: 'testimonialSection',
              location: cta?.type,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default GettingStartedHalf;
